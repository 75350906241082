import { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NeuOption } from '@neutron/react';

import { Dropdown } from '../../shared/dropdowns';

import store, { RootState } from '../../../redux/store';
import {
  getTemplateMaster,
  getAllQuestions,
  setQuestionsOfTemplate,
  getTemplatesTree,
  setChosenTemplate
} from '../../../redux/actions/Template.action';

import {
  AuthUserProps,
  ITemplate,
  Template,
  QuestionList
} from '../../../config/interfaces';

import { Container } from './SelectTemplatesDropdown.styles';

interface SelectTemplateDropDownProps {
  type: string;
  authorizedUser?: AuthUserProps;
  masterTemplateList: Template[];
  allQuestions: QuestionList[];
  roundingType?: string;
}

const SelectTemplateDropDown: FC<SelectTemplateDropDownProps> = ({
  type,
  authorizedUser,
  masterTemplateList,
  allQuestions,
  roundingType
}) => {
  const [selectedTemplateName, setSelectedTemplateName] = useState<string>();
  const [filteredMasterTemplateList, setFilteredMasterTemplateList] = useState<
    Template[]
  >([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    store.dispatch(getTemplateMaster({ condensed: false, archived: false }));

    switch (type) {
      case 'questionBank': {
        setSelectedTemplateName('All');
        store.dispatch(getAllQuestions());
        break;
      }
      default: {
        break;
      }
    }
  }, []);

  useEffect(() => {
    if (roundingType) {
      const isSupportRoundingType = !(
        roundingType === 'patient' || roundingType === 'employee'
      );
      const filtered = isSupportRoundingType
        ? masterTemplateList?.filter(
            (template: Template) =>
              !(
                template.roundingType === 'patient' ||
                template.roundingType === 'employee'
              )
          )
        : masterTemplateList?.filter(
            (template: Template) => template.roundingType === roundingType
          );
      setFilteredMasterTemplateList(filtered);
    } else {
      setFilteredMasterTemplateList(masterTemplateList);
    }

    switch (type) {
      case 'questionBank': {
        store.dispatch(setQuestionsOfTemplate(allQuestions));
        break;
      }
      default: {
        console.log('type not recognized');
      }
    }
  }, [masterTemplateList, allQuestions, roundingType]);

  useEffect(() => {
    switch (type) {
      case 'templateTree': {
        if (filteredMasterTemplateList?.length) {
          setSelectedTemplateName(filteredMasterTemplateList[0]?.name);

          store.dispatch(
            getTemplatesTree({
              templateConfigId: filteredMasterTemplateList[0]?.templateConfigId,
              userId: authorizedUser?.hcaid
            })
          );

          store.dispatch(setChosenTemplate(filteredMasterTemplateList[0]));
          const questionsToList =
            filteredMasterTemplateList[0]?.questions?.reduce(
              (
                acc: QuestionList[],
                selectedQuestion: {
                  hidden: string;
                  id: string;
                  templateLevel: string;
                }
              ) => {
                const foundQuestion = allQuestions.find(
                  (allQuestion: QuestionList) =>
                    allQuestion.questionId === selectedQuestion.id
                );
                return foundQuestion ? [...acc, foundQuestion] : [...acc];
              },
              []
            );

          store.dispatch(setQuestionsOfTemplate(questionsToList));
        }
        break;
      }
      default: {
        console.log('type not recognized');
      }
    }
  }, [filteredMasterTemplateList]);

  const onChangeTemplate = (e: any) => {
    const { value } = e.target;

    switch (type) {
      case 'questionBank': {
        if (value === 'all') {
          setSelectedTemplateName('All');
          store.dispatch(setQuestionsOfTemplate(allQuestions));
        } else {
          const selectedTemplate = filteredMasterTemplateList.find(
            (template: Template) => template.templateId === value
          );
          setSelectedTemplateName(selectedTemplate?.name);
          const questionsToList = selectedTemplate?.questions.reduce(
            (
              acc: QuestionList[],
              selectedQuestion: {
                hidden: string;
                id: string;
                templateLevel: string;
              }
            ) => {
              const foundQuestion = allQuestions.find(
                (allQuestion: QuestionList) =>
                  allQuestion.questionId === selectedQuestion.id
              );
              return foundQuestion ? [...acc, foundQuestion] : [...acc];
            },
            []
          );

          store.dispatch(setQuestionsOfTemplate(questionsToList));
        }
        break;
      }
      case 'templateTree': {
        const selectedTemplate = filteredMasterTemplateList.find(
          (template: Template) => template.templateId === value
        );
        setSelectedTemplateName(selectedTemplate?.name);
        store.dispatch(
          getTemplatesTree({
            templateConfigId: selectedTemplate?.templateConfigId,
            userId: authorizedUser?.hcaid
          })
        );
        store.dispatch(setChosenTemplate(selectedTemplate));
        const questionsToList = selectedTemplate?.questions.reduce(
          (
            acc: QuestionList[],
            selectedQuestion: {
              hidden: string;
              id: string;
              templateLevel: string;
            }
          ) => {
            const foundQuestion = allQuestions.find(
              (allQuestion: QuestionList) =>
                allQuestion.questionId === selectedQuestion.id
            );
            return foundQuestion ? [...acc, foundQuestion] : [...acc];
          },
          []
        );

        store.dispatch(setQuestionsOfTemplate(questionsToList));
        break;
      }
      default: {
        break;
      }
    }
  };
  return (
    <Container>
      <Dropdown
        classes="pl-0"
        open={open}
        placeholder={selectedTemplateName || '--'}
        width="96%"
        setOpen={setOpen}
      >
        {type === 'questionBank' ? (
          <NeuOption
            className={`dropdown-hover-item${
              selectedTemplateName === 'all' ? ' selected' : ''
            }`}
            key="templates-all"
            value="all"
            onClick={onChangeTemplate}
          >
            All
          </NeuOption>
        ) : null}
        {filteredMasterTemplateList.map((template: ITemplate) => {
          return (
            <NeuOption
              className={`dropdown-hover-item${
                selectedTemplateName === template.name ? ' selected' : ''
              }`}
              key={template.templateId}
              value={template.templateId}
              onClick={onChangeTemplate}
            >
              {template.name}
            </NeuOption>
          );
        })}
      </Dropdown>
    </Container>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  masterTemplateList: state.TemplateReducer.masterTemplateList,
  allQuestions: state.TemplateReducer.allQuestions,
  templatesTree: state.TemplateReducer.allQuestions.templateTree,
  authorizedUser: state.AuthorizedUser.authorizedUser
});

export default connect(mapReduxStateToProps)(SelectTemplateDropDown);
