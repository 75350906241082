import { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { format, subDays, sub } from 'date-fns';

import {
  NeuOption,
  NeuLabel,
  NeuButton,
  NeuInput,
  NeuButtonToggle,
  NeuIcon
} from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import RoundFooter from './RoundFooter';
import Survey from './survey';
import { Dropdown } from '../shared/dropdowns';

import store, { AppDispatch, RootState } from '../../redux/store';
import {
  clearChosenTemplate,
  setChosenTemplate,
  getTemplateById
} from '../../redux/actions/Template.action';
import {
  setRoundCancelled,
  setRoundStarted,
  clearRoundState
} from '../../redux/actions/Round.action';

import {
  logRoundClick,
  logRoundProfileClick
} from '../../utils/analyticsHelpers';
import { date } from '../../utils/helpers';

import { FinishRoundTypography } from './Round.styles';

import finishedRoundIcon from '../../assets/images/complete_round_icon.png';

import { Employee, ITemplate } from '../../config/interfaces';

const supportMasterTemplateConfigIds = {
  csrn: 'b4a91330-d7e7-11ed-b894-d16b58c37774',
  cned: 'c2a6d060-48fe-11ef-aeff-afe93ea5e8fd'
} as Record<any, string>;

interface RoundProps {
  chosenSurvey: any;
  currentRound: any;
  editingRound: boolean;
  fetchingRound: boolean;
  hideCloseButton?: boolean;
  isRoundStarted: boolean;
  isRoundCompleted: boolean;
  loadingTemplateById: boolean;
  masterTemplateList?: ITemplate[];
  pastRoundId: string;
  pastRoundTemplate: ITemplate;
  roundViewOnly: boolean;
  selectedEmployee: Employee;
  selectedTemplate: ITemplate;
  supportType?: string;
  templateById: ITemplate;
  type: string;
  clearRoundingState: () => void;
  clearStoreTemplate: () => void;
  resetRound: () => void;
  setStoreTemplate: (data: ITemplate) => void;
  startRound: () => void;
}

const Round: FC<RoundProps> = ({
  chosenSurvey,
  currentRound,
  editingRound,
  fetchingRound,
  isRoundStarted,
  isRoundCompleted,
  hideCloseButton,
  loadingTemplateById,
  masterTemplateList = [],
  pastRoundId,
  pastRoundTemplate,
  roundViewOnly,
  selectedEmployee,
  selectedTemplate,
  supportType,
  templateById,
  type,
  clearRoundingState,
  clearStoreTemplate,
  resetRound,
  setStoreTemplate,
  startRound
}) => {
  const [selectedTime, setSelectedTime] = useState<string>(
    format(new Date(), 'HH:mm')
  );
  const [selectedDateTime, setSelectedDateTime] = useState<string>(
    date.convertToDateTime(new Date())
  );
  const [day, setDay] = useState<string>('today');
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(-1);
  const [templatesForType, setTemplatesForType] = useState<ITemplate[]>(
    masterTemplateList.filter(template =>
      !(type === 'patient' || type === 'employee')
        ? template.roundingType === supportType
        : template.roundingType === type
    )
  );

  const [rendered, setRendered] = useState(false);
  const [templateFound, setTemplateFound] = useState(true);
  const [open, setOpen] = useState(false);

  const { logTrackingEvent } = useAnalyticsApi();

  const changeSelectedTemplateIndexHelper = (template: ITemplate) => {
    if (template) {
      const foundMasterTemplateIndex = templatesForType.findIndex(
        (t: ITemplate) => t.templateConfigId === template?.templateConfigId
      );
      setSelectedTemplateIndex(foundMasterTemplateIndex);
      if (Object.keys(templateById).length > 0) {
        setStoreTemplate({ ...template });
      } else if (foundMasterTemplateIndex !== -1) {
        setStoreTemplate({ ...templatesForType[foundMasterTemplateIndex] });
      }
    }
  };

  const isSupportType = useMemo(
    () => !(type === 'patient' || type === 'employee'),
    [type]
  );
  const maxTime = date.convertToDateTime(new Date());
  const minTime = useMemo(
    () => date.convertToDateTime(sub(new Date(), { days: 7 })),
    []
  );

  const roundBtnEnabled = useMemo(
    () => selectedTemplateIndex !== -1,
    [selectedTemplateIndex]
  );

  const adjustTime = useMemo(() => {
    const adjTime = new Date();
    // Patient round timestamp
    if (type === 'patient') {
      if (selectedTime) {
        adjTime.setHours(
          Number(selectedTime.split(':')[0]),
          Number(selectedTime.split(':')[1])
        );
      }
      if (day === 'yesterday')
        return date.convertDateToUTCTimestamp(subDays(adjTime, 1));
      return date.convertDateToUTCTimestamp(adjTime);
    }
    // Emp round timestamp
    const [year, month, rest] = selectedDateTime.split('-');
    if (month) {
      const [dayOfMonth, time] = rest.split('T');
      adjTime.setFullYear(Number(year), Number(month) - 1, Number(dayOfMonth));
      adjTime.setHours(Number(time.split(':')[0]), Number(time.split(':')[1]));
    }
    return date.convertDateToUTCTimestamp(adjTime);
  }, [selectedTime, selectedDateTime, day]);

  const onChangeTemplate = (e: any) => {
    const { value } = e.target;
    masterTemplateList.forEach((template: ITemplate) => {
      if (template.name === value) changeSelectedTemplateIndexHelper(template);
    });
  };

  useEffect(() => {
    setRendered(true);
    return () => {
      resetRound();
      if (type === 'employee' || type === 'support') clearStoreTemplate();
    };
  }, []);

  useEffect(() => {
    // enable round btn when template changed.
    if (selectedTemplateIndex !== -1) {
      setTemplateFound(true);
    } else if (pastRoundId && rendered) {
      setTemplateFound(false);
    } else {
      setTemplateFound(true);
    }
  }, [pastRoundId, selectedTemplateIndex]);

  useEffect(() => {
    if (selectedEmployee) {
      if (type === 'employee') {
        // Employee rounds are defaulted based on direct report templates 30, 60, 90 or standard
        const today: any = new Date();

        // Check that effectiveStartDate exists and is not string null for vendors
        let selEmpStartDate;

        if (
          selectedEmployee?.effectiveStartDate &&
          selectedEmployee?.effectiveStartDate.includes('/')
        ) {
          const dateSplit = selectedEmployee?.effectiveStartDate.split('/');
          const formatToOrbitDate = dateSplit[2] + dateSplit[0] + dateSplit[1];
          selEmpStartDate = formatToOrbitDate;
        } else if (
          selectedEmployee?.effectiveStartDate &&
          selectedEmployee?.effectiveStartDate !== 'NULL'
        ) {
          selEmpStartDate = selectedEmployee?.effectiveStartDate;
        } else {
          selEmpStartDate = date.beginningOfYear();
        }

        const formattedStDt: any = selEmpStartDate?.slice(0, 9);
        const prettyStDt: any = date.datePrettier(formattedStDt);

        const date2: any = new Date(prettyStDt); // Employee Start Date effectiveStartDate prop

        const empDaysSinceHire = Math.abs(today - date2); // subtract start day from today
        const daysSinceHired = Math.ceil(
          empDaysSinceHire / (1000 * 60 * 60 * 24)
        ); // calc days

        let template: ITemplate[];
        if (daysSinceHired <= 31) {
          template = templatesForType.filter(temp => {
            return temp.name.includes('30 Day');
          });
          changeSelectedTemplateIndexHelper(template[0]);
        } else if (daysSinceHired >= 32 && daysSinceHired <= 61) {
          template = templatesForType.filter(temp => {
            return temp.name === '60 Day Rounding Template';
          });
          changeSelectedTemplateIndexHelper(template[0]);
        } else if (daysSinceHired >= 61 && daysSinceHired <= 91) {
          template = templatesForType.filter(temp => {
            return temp.name === '90 Day Rounding Template';
          });
          changeSelectedTemplateIndexHelper(template[0]);
        } else if (daysSinceHired > 91 || !daysSinceHired) {
          template = templatesForType.filter(temp => {
            return temp.name === 'Standard Employee Rounding';
          });
          changeSelectedTemplateIndexHelper(template[0]);
        }
      } else {
        // For now CSC template is the only default support master template
        const defaultSupportTemplate = templatesForType.find(temp => {
          return (
            temp.roundingType === supportType &&
            temp.templateConfigId ===
              supportMasterTemplateConfigIds[supportType]
          );
        });
        if (defaultSupportTemplate)
          changeSelectedTemplateIndexHelper(defaultSupportTemplate);
      }
    }
  }, [selectedEmployee, templatesForType]);

  const onChangeTime = (e: any) =>
    type === 'patient'
      ? setSelectedTime(e.detail.value)
      : setSelectedDateTime(e.detail.value);

  useEffect(() => {
    setTemplatesForType(
      masterTemplateList.filter(template =>
        isSupportType
          ? template.roundingType === supportType
          : template.roundingType === type
      )
    );
  }, [masterTemplateList]);

  useEffect(() => {
    const filteredTemp = templatesForType.filter(
      template => template.templateId === pastRoundTemplate?.templateId
    )[0];
    if ((roundViewOnly || editingRound) && templatesForType.length > 0) {
      clearStoreTemplate();
      if (!filteredTemp && pastRoundTemplate?.templateId) {
        store.dispatch(
          getTemplateById({
            templateId: pastRoundTemplate?.templateId,
            updateMasterTemplateFlag: false,
            updateTemplateConfigFlag: false
          })
        );
      } else if (filteredTemp && pastRoundTemplate?.templateId) {
        changeSelectedTemplateIndexHelper(filteredTemp);
      } else if (!pastRoundTemplate) {
        setSelectedTemplateIndex(-1);
      }
    }
  }, [pastRoundTemplate, templatesForType]);

  useEffect(() => {
    if (Object.keys(templateById).length === 0) {
      if (type === 'patient' && templatesForType.length > 0) {
        if (Object.keys(selectedTemplate).length === 0) {
          changeSelectedTemplateIndexHelper(
            pastRoundId ? selectedTemplate : templatesForType[0]
          );
        } else {
          changeSelectedTemplateIndexHelper(selectedTemplate);
        }
      }
    } else {
      changeSelectedTemplateIndexHelper(templateById);
    }
  }, [templateById, templatesForType]);

  useEffect(() => {
    if (
      !fetchingRound &&
      !loadingTemplateById &&
      (roundViewOnly || editingRound) &&
      Object.keys(selectedTemplate).length > 0 &&
      !isRoundStarted
    )
      startRound();
  }, [
    isRoundStarted,
    editingRound,
    fetchingRound,
    loadingTemplateById,
    roundViewOnly,
    selectedTemplate
  ]);

  useEffect(() => {
    if (
      currentRound &&
      currentRound.roundDate &&
      date.isOrbitTimestamp(currentRound.roundDate) &&
      type === 'patient'
    ) {
      setSelectedTime(format(date.parseDate(currentRound.roundDate), 'HH:mm'));
    } else if (
      currentRound &&
      currentRound.roundDate &&
      date.isOrbitTimestamp(currentRound.roundDate) &&
      // TODO: Is this needed
      (type === 'employee' || type === 'support')
    ) {
      setSelectedDateTime(
        date.convertToDateTime(date.parseDate(currentRound.roundDate))
      );
    }
  }, [currentRound.roundDate]);

  return (
    <div
      id="Round"
      className="d-flex flex-column w-100"
      style={{
        maxHeight: 'calc(100vh - 186px)',
        background: '#F6F6F6'
      }}
    >
      {(((roundViewOnly || editingRound) &&
        !isRoundCompleted &&
        !fetchingRound &&
        !loadingTemplateById &&
        !hideCloseButton) ||
        !templateFound) && (
        <div
          id="CloseRoundButton"
          className="d-flex flex-row pl-8"
          style={{
            height: 22,
            alignItems: 'center',
            marginTop: '15px'
          }}
        >
          <div
            className="d-flex flex-row pl-8"
            style={{
              cursor: 'pointer'
            }}
          >
            <NeuButton
              color="primary"
              size="small"
              fill="raised"
              onClick={() => {
                setDay('today');
                setSelectedTime(format(new Date(), 'HH:mm'));
                setSelectedDateTime(date.convertToDateTime(new Date()));
                clearRoundingState();
              }}
            >
              <NeuIcon small>close</NeuIcon>
              Close Round
            </NeuButton>
          </div>
          {templateFound && (
            <div className="d-flex flex-row pl-8">
              <p style={{ fontStyle: 'italic', marginLeft: '5px' }}>
                You&apos;re viewing a previous round.
              </p>
            </div>
          )}
        </div>
      )}
      {!loadingTemplateById && !templateFound ? (
        <div className="d-flex align-self-center align-items-center mt-9">
          <FinishRoundTypography>No template found!</FinishRoundTypography>
        </div>
      ) : (
        <>
          {!isRoundCompleted && !fetchingRound && !loadingTemplateById && (
            <div
              className="d-flex flex-row w-100 mt-5 ml-9"
              style={{
                flex: 1,
                maxHeight: '112px'
              }}
            >
              <div
                className="d-flex flex-column"
                style={{ marginLeft: '40px' }}
              >
                <div
                  style={{
                    width: 267,
                    height: 22
                  }}
                >
                  <NeuLabel
                    color="#191919"
                    content="Template"
                    position="fixed"
                    for="default"
                  />
                </div>
                <Dropdown
                  classes="pl-0"
                  disabled={
                    editingRound ||
                    roundViewOnly ||
                    isRoundStarted ||
                    type === 'support'
                  }
                  open={open}
                  placeholder={
                    selectedTemplateIndex > 0
                      ? templatesForType[selectedTemplateIndex]?.name
                      : templatesForType[0]?.name
                  }
                  disabledWithTransparent={type === 'support' && !roundViewOnly}
                  width="96%"
                  styles={{ paddingTop: '3px' }}
                  setOpen={setOpen}
                >
                  {masterTemplateList &&
                    templatesForType.map((template: ITemplate) => {
                      return (
                        <NeuOption
                          className={`dropdown-hover-item${
                            template.name ===
                            templatesForType[selectedTemplateIndex]?.name
                              ? ' selected'
                              : ''
                          }`}
                          key={template.templateConfigId}
                          value={template.name}
                          onClick={onChangeTemplate}
                        >
                          {template.name}
                        </NeuOption>
                      );
                    })}
                </Dropdown>
                {!loadingTemplateById &&
                  !isRoundStarted &&
                  !pastRoundId &&
                  selectedTemplateIndex !== -1 && (
                    <NeuButton
                      className="mt-2"
                      style={{ width: 178, height: 40 }}
                      onClick={
                        roundBtnEnabled
                          ? () => {
                              logTrackingEvent(
                                logRoundClick('start', supportType)
                              );
                              startRound();
                            }
                          : undefined
                      }
                      disabled={
                        !roundBtnEnabled ||
                        (day === 'today' &&
                          format(new Date(), 'HH:mm') < selectedTime) ||
                        selectedDateTime > maxTime
                      }
                    >
                      Start a New Round
                    </NeuButton>
                  )}
              </div>
              {type === 'patient' &&
                !loadingTemplateById &&
                !roundViewOnly && ( // remove later with emp round
                  <div
                    className="d-flex flex-row px-5"
                    style={{
                      width: 260,
                      height: 22,
                      marginTop: 4
                    }}
                  >
                    <NeuButtonToggle
                      width="260px"
                      value={day}
                      className="mt-5"
                      onNeuChange={(e: any) => {
                        setDay(e.detail.value);
                      }}
                    >
                      <NeuButton value="today">Today</NeuButton>
                      <NeuButton value="yesterday">Yesterday</NeuButton>
                    </NeuButtonToggle>
                  </div>
                )}
              <div
                className="d-flex flex-column pl-8"
                style={{
                  width: 267,
                  height: 22,
                  marginTop: 4
                }}
              >
                <div
                  style={{
                    width: 267,
                    height: 22
                  }}
                >
                  <NeuLabel
                    color="#191919"
                    content={type === 'patient' ? 'Time' : 'Date & Time'}
                    position="fixed"
                    for="default"
                  />
                </div>
                {type === 'patient' && (
                  <NeuInput
                    type="time"
                    autocomplete="off"
                    inputmode="search"
                    enterkeyhint="search"
                    autocorrect="off"
                    disabled={roundViewOnly || fetchingRound}
                    error={
                      (day === 'today' && format(new Date(), 'HH:mm')) <
                      selectedTime
                    }
                    value={selectedTime}
                    onNeuChange={onChangeTime}
                    onNeuFocus={() =>
                      logTrackingEvent(logRoundProfileClick('date'))
                    }
                  />
                )}
                {(type === 'employee' || type === 'support') && (
                  <NeuInput
                    type="datetime-local"
                    autocomplete="off"
                    inputmode="search"
                    enterkeyhint="search"
                    autocorrect="off"
                    max={maxTime}
                    min={minTime}
                    error={selectedDateTime > maxTime}
                    disabled={roundViewOnly || fetchingRound}
                    style={{ minWidth: '17rem' }}
                    value={selectedDateTime}
                    onNeuChange={onChangeTime}
                    onNeuFocus={() =>
                      logTrackingEvent(logRoundProfileClick('date'))
                    }
                  />
                )}
                {!roundViewOnly &&
                  ((day === 'today' &&
                    format(new Date(), 'HH:mm') < selectedTime) ||
                    selectedDateTime > maxTime) && (
                    <span
                      style={{
                        color: '#CE3637',
                        fontSize: 14,
                        marginTop: 4,
                        fontWeight: 'bold'
                      }}
                    >
                      Time cannot be in the future
                    </span>
                  )}
              </div>
            </div>
          )}
          {isRoundStarted &&
            !isRoundCompleted &&
            !fetchingRound &&
            !loadingTemplateById && (
              <div>
                <Survey
                  selectedTimestamp={adjustTime}
                  type={type}
                  supportType={supportType}
                />
                {!roundViewOnly && Object.keys(chosenSurvey).length > 0 && (
                  <RoundFooter type={type} />
                )}
              </div>
            )}
          {isRoundCompleted && (
            <div className="d-flex align-self-center align-items-center mt-9">
              <img
                src={finishedRoundIcon}
                alt="checkmark in circle with ribbon edge"
              />
              <FinishRoundTypography>
                You just completed a round!
              </FinishRoundTypography>
            </div>
          )}
        </>
      )}
    </div>
  );
};
const mapReduxStateToProps = (state: RootState) => ({
  chosenSurvey: state.TemplateReducer.chosenSurvey,
  currentRound: state.RoundReducer.currentRound,
  editingRound: state.RoundReducer.editingRound,
  fetchingRound: state.RoundReducer.fetchingRound,
  isRoundStarted: state.RoundReducer.isRoundStarted,
  isRoundCompleted: state.RoundReducer.isRoundCompleted,
  loadingTemplateById: state.TemplateReducer.templateActiveInactiveLoader,
  masterTemplateList: state.TemplateReducer.masterTemplateList,
  pastRoundId: state.RoundReducer.pastRoundId,
  pastRoundTemplate: state.TemplateReducer.pastRoundTemplate,
  roundViewOnly: state.RoundReducer.roundViewOnly,
  selectedEmployee: state.UserReducer.selectedEmployee,
  selectedTemplate: state.TemplateReducer.selectedTemplate,
  templateById: state.TemplateReducer.templateById
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  clearRoundingState: () => dispatch(clearRoundState()),
  clearStoreTemplate: () => dispatch(clearChosenTemplate()),
  resetRound: () => dispatch(setRoundCancelled()),
  setStoreTemplate: (data: ITemplate) => dispatch(setChosenTemplate(data)),
  startRound: () => dispatch(setRoundStarted())
});

export default connect(mapReduxStateToProps, mapDispatchToProps)(Round);

// Future use if change to :30 increments

// <Dropdown
//   style={{
//     width: 267,
//     height: 22,
//     flex: 1,
//     display: 'flex',
//     flexDirection: 'row'
//   }}
//   interface="popover"
//   onNeuChange={onChangeTime}
//   selectedText={timeStringList.current[selectedTimeIndex]}
// >
//   {timeStringList.current.map(time => {
//     return <NeuOption value={time}>{time}</NeuOption>;
//   })}
// </Dropdown>;

// useEffect(() => {
//   if (!currentTime.current) {
//     new Date().toLocaleTimeString();
//     currentTime.current = new Date();

//     timeStringList.current.push(
//       format(currentTime.current.setHours(0, 0, 0, 0), 'hh:mm a')
//     );
//     try {
//       for (
//         let nextTime = add(currentTime.current, { minutes: 30 });
//         compareAsc(nextTime, new Date()) === -1;
//         nextTime = add(nextTime, { minutes: 30 })
//       ) {
//         timeStringList.current.push(format(nextTime, 'hh:mm a'));
//       }
//       timeStringList.current.push(format(new Date(), 'hh:mm a'));
//       timeStringList.current.reverse();
//     } catch (err) {
//       console.log(`ERROR IS :${err}`);
//     }
//   }
// }, [masterTemplateList]);

// useEffect(() => {
//   if (currentTime.current) {
//     currentTime.current = format(new Date(), 'hh:mm');
//   }
// }, [masterTemplateList]);
