import { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import HistorySideDrawer from '../../components/historySideDrawer';
import HistorySideNav from '../../components/historySideNav/HistorySideNav';
import Round from '../../components/round';
import store, { RootState } from '../../redux/store';
import { getPatientRoundHistory } from '../../redux/actions/Report.action';
import { getActivePatientTemplates } from '../../redux/actions/Template.action';
import { getTaskByAccountNumber } from '../../redux/actions/Task.action';
import { clearSelectedPatient } from '../../redux/actions/User.action';
import { getRound } from '../../redux/actions/Round.action';
import {
  AuthUserProps,
  IFacility,
  Patient,
  RoundHistory,
  Task
} from '../../config/interfaces';

interface PatientProfileProps {
  authorizedUser?: AuthUserProps;
  facilities: IFacility[];
  selectedPatient?: Patient;
  patientRoundHistory?: RoundHistory;
  tasks?: Task[];
  unListedPatientParams?: Patient;
}

const PatientProfile: FC<PatientProfileProps> = ({
  authorizedUser,
  selectedPatient,
  facilities,
  patientRoundHistory,
  tasks,
  unListedPatientParams
}) => {
  const [selectedTab, setSelectedTab] = useState<string>('');
  // const [sideDrawerData, setSideDrawerData] = useState<any>({});

  const navigate = useNavigate();
  const { state } = useLocation();
  const data = state as { isUnlistedPatient?: boolean };
  const { isUnlistedPatient = false } = data || {};

  const userFacility = useMemo(
    () =>
      facilities.filter(
        facility => facility.id === authorizedUser?.facilityId
      )[0],
    [facilities, authorizedUser?.facilityId]
  );

  useEffect(() => {
    if (!isUnlistedPatient && selectedPatient) {
      store.dispatch(
        getPatientRoundHistory({
          facilityId: authorizedUser?.facilityId,
          patientId: selectedPatient.urn
        })
      );
      // Get active patient templates
      store.dispatch(
        getActivePatientTemplates({
          division: userFacility.division,
          facilityId: authorizedUser?.facilityId,
          unitId: selectedPatient?.unitId,
          condensed: false,
          archived: false
        })
      );
      if (selectedPatient.accountNum)
        store.dispatch(
          getTaskByAccountNumber({
            accountNumber: selectedPatient.accountNum,
            facilityMnemonic: authorizedUser?.facilityId
          })
        );
    } else if (isUnlistedPatient) {
      if (selectedPatient?.lastRoundId || unListedPatientParams?.lastRoundId)
        store.dispatch(
          getRound({
            roundId:
              selectedPatient?.lastRoundId ||
              unListedPatientParams?.lastRoundId,
            fromHistorydrawer: false
          })
        );
      if (selectedPatient?.accountNum || unListedPatientParams?.mrn)
        store.dispatch(
          getTaskByAccountNumber({
            accountNumber:
              selectedPatient?.accountNum || unListedPatientParams?.mrn,
            facilityMnemonic: authorizedUser?.facilityId
          })
        );
      // Get active patient templates
      store.dispatch(
        getActivePatientTemplates({
          division: userFacility.division,
          facilityId: authorizedUser?.facilityId,
          unitId: unListedPatientParams?.unitId,
          condensed: false,
          archived: false
        })
      );
    } else {
      navigate('/patients/census');
    }
  }, []);

  useEffect(() => {
    return () => {
      store.dispatch(clearSelectedPatient());
    };
  }, []);

  return (
    <div
      id="Patient-Profile"
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: 40,
        flex: 1,
        backgroundColor: 'white',
        height: '100%'
      }}
    >
      <HistorySideNav
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        type="patient"
      />
      <HistorySideDrawer
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        selectedPerson={selectedPatient}
        roundHistory={patientRoundHistory}
        tasks={tasks}
        type="patient"
      />
      <Round type="patient" />
    </div>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  isRoundStarted: state.RoundReducer.isRoundStarted,
  authorizedUser: state.AuthorizedUser.authorizedUser,
  facilities: state.UserReducer.facilities,
  selectedPatient: state.UserReducer.selectedPatient,
  tasks: state.TaskReducer.patientTasks,
  patientRoundHistory: state.ReportReducer.patientRoundHistory,
  unListedPatientParams: state.UserReducer.unlistedPatientRoundParams
});

export default connect(mapReduxStateToProps)(PatientProfile);
