/**
 * External Imports
 */
import { FC, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import { NeuTab, NeuIcon } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

/**
 * Internal Imports
 */

import { logTableStart } from '../../../utils/analyticsHelpers';
import { setLastPath } from '../../../utils/helpers';

/**
 * Styling Imports
 */
import {
  PopItem,
  IconPositioning,
  InlineRelativeContainer,
  PopContent,
  SupportDropDownContainer
} from '../../Header/mainNav/MainNav.styles';
import { useUserRolesRef } from '../../../services/UserRoles';

const SupportDropdown: FC<{}> = () => {
  const [supportDropdownState, setSupportDropdownState] = useState(false);
  const [, setShowPopover] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { logTrackingEvent } = useAnalyticsApi();

  const {
    isEnterpriseAdmin,
    isMultipleRounders,
    isNonSupportRounder,
    isAllSupportRounder,
    isCSRNRounder,
    isOnlyTwoTypeRounders,
    isCNEdRounder
  } = useUserRolesRef();
  return (
    <>
      {(isEnterpriseAdmin || (isNonSupportRounder && isAllSupportRounder)) && (
        <>
          <NeuTab
            id="Supports-Tab"
            onBlur={() => setSupportDropdownState(false)}
            navy
            active={pathname.includes('support')}
            onClick={() => setSupportDropdownState(!supportDropdownState)}
          >
            <IconPositioning>
              Support
              <NeuIcon small>arrow_drop_down</NeuIcon>
            </IconPositioning>
          </NeuTab>
          <InlineRelativeContainer onBlur={() => setShowPopover(false)}>
            <SupportDropDownContainer showReportDropdown={supportDropdownState}>
              {(isEnterpriseAdmin || isCSRNRounder) && (
                <PopItem
                  button
                  onMouseDown={() => {
                    // store.dispatch(clearSelectedEmployee());
                    setLastPath('/support/csc-list');
                    navigate('/support/csc-list');
                    logTrackingEvent(logTableStart('/support/csc-list'));
                  }}
                >
                  <PopContent>CSC Rounding</PopContent>
                </PopItem>
              )}
              {(isEnterpriseAdmin || isCNEdRounder) && (
                <PopItem
                  button
                  onMouseDown={() => {
                    // store.dispatch(clearSelectedEmployee());
                    setLastPath('/support/cned-list');
                    navigate('/support/cned-list');
                    logTrackingEvent(logTableStart('/support/cned-list'));
                  }}
                >
                  <PopContent>CNEd Rounding</PopContent>
                </PopItem>
              )}
            </SupportDropDownContainer>
          </InlineRelativeContainer>
        </>
      )}
      {((!isMultipleRounders && !isEnterpriseAdmin && isCSRNRounder) ||
        (isOnlyTwoTypeRounders && isAllSupportRounder) ||
        (isMultipleRounders && isNonSupportRounder && !isCNEdRounder)) && (
        <Link
          onClick={() => {
            setLastPath('/support/csc-list');
          }}
          key="CSRN-Tab"
          id="CSRN-Tab"
          to="/support/csc-list"
        >
          <NeuTab active={pathname.includes('support/csc-list')} navy>
            CSC Rounding
          </NeuTab>
        </Link>
      )}
      {((!isMultipleRounders && !isEnterpriseAdmin && isCNEdRounder) ||
        (isOnlyTwoTypeRounders && isAllSupportRounder) ||
        (isMultipleRounders && isNonSupportRounder && !isCSRNRounder)) && (
        <Link
          onClick={() => {
            setLastPath('/support/cned-list');
          }}
          key="CNED-Tab"
          id="CNED-Tab"
          to="/support/cned-list"
        >
          <NeuTab active={pathname.includes('support/cned-list')} navy>
            CNEd Rounding
          </NeuTab>
        </Link>
      )}
    </>
  );
};

export default SupportDropdown;
