import { FC, useEffect, useState, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import {
  NeuTable,
  NeuTableRow,
  NeuTableHeading,
  NeuTableBody,
  NeuLabel,
  NeuPaginator,
  NeuCheckbox
} from '@neutron/react';
import { NeuCheckboxChangeEventDetail } from '@neutron/core';
import store, { RootState } from '../../../redux/store';
import { getStoreDischargeParams } from '../../../redux/actions/User.action';
import Loading from '../../shared/Loading';
import NoDataSplashView from '../../noDataSplashView/NoDataSplashView';
import { useDebounceValue } from '../../../utils/debouncers';
import { calculateStayTime } from '../../../utils/helpers';
import { Patient } from '../../../config/interfaces';
import { ToDoLabel, PaginationContainer, ToDoRow } from './Discharge.styles';

interface DischargeProps {
  patientsToRound?: Patient[];
  searchTerm: string;
  facilityId: string;
  isLoading?: boolean;
}

const Discharge: FC<DischargeProps> = ({
  patientsToRound,
  searchTerm,
  facilityId,
  isLoading
}) => {
  const [pageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [ptr, setPtr] = useState<Patient[]>([]);
  const [filteredPtr, setFilteredPtr] = useState<Patient[]>([]);
  const accountsToDischarge = useRef<string[]>([]);
  const [isTableEmpty, setIsTableEmpty] = useState(false);
  const [isTableEmptySearch, setIsTableEmptySearch] = useState(false);
  const [active, setActive] = useState<string>('Patient');
  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('asc');
  const [sortKey, setSortKey] = useState<string>('firstName');

  // debouncing search terms
  const debouncedNameSearchTerm = useDebounceValue(searchTerm, 275);

  useEffect(() => {
    if (patientsToRound) {
      setPtr(patientsToRound);
      // Sorting the list A-Z for initial display
      const sorted = patientsToRound.sort((a: Patient, b: Patient) =>
        a.firstName > b.firstName ? 1 : -1
      );
      setIsTableEmpty(false);
      setFilteredPtr(sorted);
    } else {
      setIsTableEmpty(true);
    }
  }, [patientsToRound]);

  useEffect(() => {
    const q = new RegExp(
      debouncedNameSearchTerm.trim().replace(/\\/g, ''),
      'i'
    );

    const filtered = ptr.filter(
      patient =>
        `${patient.firstName} ${patient.lastName}`.match(q) ||
        `${patient.lastName} ${patient.firstName}`.match(q)
    );
    if (filtered.length === 0) {
      setIsTableEmptySearch(true);
    } else {
      setIsTableEmptySearch(false);
      setFilteredPtr(filtered);
    }
  }, [debouncedNameSearchTerm, ptr]);

  const pageNumberChanged = (pageNum: number) => {
    if (pageNum) setPageNumber(pageNum);
  };

  const sortByHeading = (key: string, column: string) => {
    if (key === sortKey && sortDir === 'asc') {
      setSortDir('desc');
    } else {
      setSortDir('asc');
    }
    setActive(column);
    setSortKey(key);
  };

  const sortedPtr = useMemo(() => {
    const stateCopy = [...filteredPtr];
    const sorted = stateCopy.sort((a: Patient, b: Patient) => {
      if (sortKey === 'lengthOfStay') {
        if (a[sortKey] === b[sortKey]) {
          return 0;
        }
        if (!a[sortKey]) {
          return sortDir === 'asc' ? 1 : -1;
        }
        if (!b[sortKey]) {
          return sortDir === 'asc' ? -1 : 1;
        }
        if (sortDir === 'asc') {
          return b[sortKey] - a[sortKey];
        }
        return a[sortKey] - b[sortKey];
      }
      if (sortKey === 'firstName') {
        if (sortDir === 'asc') {
          return `${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}`
            ? 1
            : -1;
        }
        return `${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}`
          ? -1
          : 1;
      }
      if (a[sortKey] === b[sortKey]) {
        return 0;
      }
      if (!a[sortKey]) {
        return sortDir === 'asc' ? 1 : -1;
      }
      if (!b[sortKey]) {
        return sortDir === 'asc' ? -1 : 1;
      }
      if (sortDir === 'asc') {
        return a[sortKey]! > b[sortKey]! ? 1 : -1;
      }
      return a[sortKey]! > b[sortKey]! ? -1 : 1;
    });
    return sorted;
  }, [filteredPtr, sortDir, sortKey]);

  const patientsData = useMemo(() => {
    return sortedPtr.slice(
      pageNumber * pageSize - pageSize,
      pageNumber * pageSize
    );
  }, [pageNumber, pageSize, sortedPtr]);

  const onCheck = (detail: NeuCheckboxChangeEventDetail, accNum: string) => {
    if (detail.checked) {
      accountsToDischarge.current.push(accNum);
    } else {
      const index = accountsToDischarge.current.indexOf(accNum);
      accountsToDischarge.current.splice(index, 1);
    }

    const param = `{"facilityId":${facilityId},"accountNumbers":"${accountsToDischarge.current}"}`;
    store.dispatch(getStoreDischargeParams(JSON.parse(param.toString())));
  };
  const handleIconForLengthOfStay = (dir: string | undefined) => {
    if (dir === 'asc') {
      return 'desc';
    }
    return 'asc';
  };

  return (
    <NeuTable>
      <NeuTableRow
        style={{ backgroundColor: 'white', height: '56px' }}
        columns="{'Patient': '45%', 'Room': '20%', 'Stay': '35%'}"
        header
      >
        <NeuTableHeading
          id="Discharge-Table-Patient-Column"
          slot="Patient"
          icon={
            !(active === 'Patient') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('firstName', 'Patient')}
          active={active === 'Patient'}
        >
          Current Patients
        </NeuTableHeading>
        <NeuTableHeading
          id="Discharge-Table-Room-Column"
          slot="Room"
          icon={
            !(active === 'Room') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          active={active === 'Room'}
          onClick={() => sortByHeading('room', 'Room')}
        >
          Room
        </NeuTableHeading>

        <NeuTableHeading
          id="Discharge-Table-Length-Column"
          slot="Stay"
          icon={
            !(active === 'Stay') ? 'asc' : handleIconForLengthOfStay(sortDir)
          }
          onClick={() => sortByHeading('lengthOfStay', 'Stay')}
          active={active === 'Stay'}
        >
          Length of Stay
        </NeuTableHeading>
      </NeuTableRow>
      {!(isTableEmpty || isTableEmptySearch) && (
        <>
          <NeuTableBody>
            {!isLoading &&
              patientsData.map((patient: Patient) => {
                return (
                  <ToDoRow
                    id={`Discharge-Row-${patient.mrn}`}
                    columns="{'Patient': '45%', 'Room': '20%', 'Stay': '35%'}"
                    size="large"
                    key={patient.encounterId}
                  >
                    <ToDoLabel color="primary-70" slot="Patient">
                      <NeuCheckbox
                        style={{ marginRight: 5 }}
                        color="primary"
                        onNeuChange={e => onCheck(e.detail, patient.accountNum)}
                      />
                      {patient.firstName} {patient.lastName}
                    </ToDoLabel>
                    <NeuLabel style={{ marginLeft: 10 }} slot="Room">
                      {patient.room}
                    </NeuLabel>
                    <NeuLabel style={{ marginLeft: 30 }} slot="Stay">
                      {patient.lengthOfStay !== -1
                        ? calculateStayTime(patient.lengthOfStay)
                        : ''}
                    </NeuLabel>
                  </ToDoRow>
                );
              })}
            {isLoading && <Loading centered={false} />}
          </NeuTableBody>
          <PaginationContainer>
            <NeuPaginator
              total={filteredPtr.length}
              rowsPerPage={pageSize}
              onNeuChange={e => pageNumberChanged(e.detail.pageNumber)}
            />
          </PaginationContainer>
        </>
      )}
      {isTableEmptySearch && <NoDataSplashView type="dischargeSearch" />}
      {isTableEmpty && <NoDataSplashView type="discharge" />}
    </NeuTable>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    isLoading: state.UserReducer.isLoading
  };
};

export default connect(mapReduxStateToProps)(Discharge);
