import { FC, useEffect, useState } from 'react';
import { NeuButton, NeuIcon } from '@neutron/react';
import { connect } from 'react-redux';

import store, { RootState } from '../../../redux/store';
import { getDischarge } from '../../../redux/actions/User.action';

interface DischargeProps {
  paramsToDischarge: { accountNumbers: string; facilityId: string };
}

const DischargeBtn: FC<DischargeProps> = ({ paramsToDischarge }) => {
  const [enableButtonFlag, setEnableButtonFlag] = useState<boolean>(true);

  useEffect(() => {
    if (paramsToDischarge) {
      if (
        paramsToDischarge.accountNumbers &&
        paramsToDischarge.accountNumbers !== ''
      ) {
        setEnableButtonFlag(false);
      } else {
        setEnableButtonFlag(true);
      }
    }
  }, [paramsToDischarge]);
  const dischargePatient = () => {
    if (paramsToDischarge?.accountNumbers !== '')
      store.dispatch(getDischarge(paramsToDischarge));
    else {
      // TODO: Should we toast here
      console.log(`please select a patient to discharge`);
    }
  };
  return (
    <NeuButton
      id="Discharge-Button"
      className="mr-5"
      color="primary"
      fill="raised"
      onClick={dischargePatient}
      disabled={enableButtonFlag}
    >
      <NeuIcon className="mr-2" feedback="default">
        add
      </NeuIcon>
      {/* {view === 'issues' && 'Add Issue'} */}
      Discharge Scheduled Patients
    </NeuButton>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    paramsToDischarge: state.UserReducer.dischargeParams,
    selectedFacility: state.AdminReducer.selectedFacility
  };
};
export default connect(mapReduxStateToProps)(DischargeBtn);
