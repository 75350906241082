import { FC, useCallback, useMemo, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { NeuTableRow, NeuContainer, NeuIcon } from '@neutron/react';

import { format, differenceInDays } from 'date-fns';

import Table from '../../shared/table';
import { StoplightColorContainer } from './StoplightTable.styles';
import {
  openModal,
  setModalType,
  setModalAction
} from '../../../redux/actions/Modal.action';
import { date } from '../../../utils/helpers';

import { AuthUserProps, Filter, Stoplight } from '../../../config/interfaces';

import {
  StyledBoldLabel,
  StyledLabel,
  StyledTableHeading
} from '../../shared/table/Table.styles';
import store, { RootState } from '../../../redux/store';
import {
  getStoplightById,
  clearStoplightFilter,
  setCurrentStoplightPage,
  setStoplightSort
} from '../../../redux/actions/Stoplight.action';
import { useStoplightsApi } from '../../../utils/api';
import { useDebounceValue } from '../../../utils/debouncers';

interface IStoplightTableProps {
  authorizedUser: AuthUserProps;
  location: string;
  searchTerm: string;
  selectedUnits: { id: string; unit: string }[];
  stoplightFilter: Filter;
  locationStoplights: Stoplight[];
  totalResults: number;
  currentStoplightPage: number;
  loading: boolean;
}

const StoplightTable: FC<IStoplightTableProps> = ({
  authorizedUser,
  location,
  searchTerm,
  selectedUnits,
  stoplightFilter,
  locationStoplights,
  totalResults,
  currentStoplightPage,
  loading
}) => {
  const dispatch = useDispatch();
  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('desc');
  const [sortKey, setSortKey] = useState<string>('created');
  const [active, setActive] = useState<string>('created');

  const dbSearchTerm = useDebounceValue(searchTerm, 300);

  const isStoplightTableEmpty = useMemo(
    () => !dbSearchTerm && locationStoplights.length === 0,
    [dbSearchTerm, locationStoplights]
  );

  const isStoplightTableEmptySearch = useMemo(
    () => dbSearchTerm && locationStoplights.length === 0,
    [dbSearchTerm, locationStoplights]
  );

  useEffect(() => {
    // clear stoplight filters & reset page when navigating away
    return () => {
      dispatch(clearStoplightFilter());
      dispatch(setCurrentStoplightPage(1));
      dispatch(setStoplightSort({ sortKey: 'created', sortDir: 'desc' }));
    };
  }, []);

  useEffect(() => {
    const stoplightAction = useStoplightsApi({
      authorizedUser,
      currentStoplightPage,
      searchTerm: dbSearchTerm,
      location,
      selectedUnits,
      sortKey,
      sortDir,
      stoplightFilter
    });
    store.dispatch(stoplightAction);
  }, [
    authorizedUser,
    currentStoplightPage,
    dbSearchTerm,
    location,
    selectedUnits,
    sortKey,
    sortDir,
    stoplightFilter
  ]);

  useEffect(() => {
    dispatch(setStoplightSort({ sortKey, sortDir }));
  }, [sortDir, sortKey]);

  const sortByHeading = (key: string) => {
    if (key === sortKey && sortDir === 'asc') {
      setSortDir('desc');
    } else {
      setSortDir('asc');
    }
    setSortKey(key);
    setActive(key);
  };

  const openStoplight = useCallback((stoplight: Stoplight) => {
    if (stoplight.id) {
      dispatch(getStoplightById(stoplight.id));
      if (
        stoplight.createdBy ===
        `${authorizedUser.firstName} ${authorizedUser.lastName}`
      ) {
        dispatch(setModalType('Edit Stoplight'));
        dispatch(setModalAction('EDIT'));
      } else {
        dispatch(setModalType('Edit Stoplight'));
        dispatch(setModalAction('VIEW'));
      }

      dispatch(openModal());
    }
  }, []);

  const cols =
    '{"Created":"13%","Location":"10%","Stoplight Category":"17%","Description":"20%","Attached Issues":"8%","Last Comment":"20%","Status": "12%"}';

  const headers: JSX.Element[] = [
    <StyledTableHeading
      id="Stoplight-Table-Created-Column"
      key="created"
      slot="Created"
      icon={
        !(active === 'created') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('created')}
      active={active === 'created'}
    >
      Created
    </StyledTableHeading>,
    <StyledTableHeading
      id="Stoplight-Table-Location-Column"
      key="location"
      slot="Location"
      icon="none"
      // onClick={() => sortByHeading('location')}
      // active={active === 'location'}
    >
      {location === 'patient' ? 'Location' : 'Department'}
    </StyledTableHeading>,
    <StyledTableHeading
      id="Stoplight-Table-Category-Column"
      key="category"
      slot="Stoplight Category"
      icon={
        !(active === 'category') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('category')}
      active={active === 'category'}
    >
      Category/Subcategory
    </StyledTableHeading>,
    <StyledTableHeading
      id="Stoplight-Table-Created-By-Column"
      key="details"
      slot="Description"
      icon="none"
    >
      Description
    </StyledTableHeading>,
    <StyledTableHeading
      id="Stoplight-Table-Issues-Column"
      key="attachedIssues"
      slot="Attached Issues"
      icon={
        !(active === 'attachedIssues')
          ? 'asc'
          : sortDir === 'asc'
          ? 'desc'
          : 'asc'
      }
      onClick={() => sortByHeading('attachedIssues')}
      active={active === 'attachedIssues'}
    >
      Issues
    </StyledTableHeading>,
    <StyledTableHeading
      id="Stoplight-Table-Update-Column"
      key="lastUpdate"
      slot="Last Comment"
      icon={
        !(active === 'lastUpdate') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('lastUpdate')}
      active={active === 'lastUpdate'}
    >
      Last Comment
    </StyledTableHeading>,
    <StyledTableHeading
      id="Stoplight-Table-Status-Column"
      key="status"
      slot="Status"
      icon={!(active === 'status') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'}
      onClick={() => sortByHeading('status')}
      active={active === 'status'}
    >
      Status
    </StyledTableHeading>
  ];

  const rows = locationStoplights?.map((stoplight: Stoplight) => (
    <NeuTableRow
      id={`Stoplight-Row-${stoplight.friendlyId}`}
      key={stoplight.friendlyId}
      columns={cols}
      size="large"
      onClick={() => openStoplight(stoplight)}
      color={stoplight.status === 'Completed' ? 'gray-0' : 'plain-0'}
    >
      <NeuContainer className="h-100 px-0" slot="Created">
        <StyledBoldLabel>{stoplight.friendlyId}</StyledBoldLabel>
        <StyledBoldLabel color="gray-90" style={{ fontWeight: 600 }}>
          {stoplight.createdBy}
        </StyledBoldLabel>
        <StyledLabel>{date.dayOfYrFull(stoplight.created)}</StyledLabel>
        {stoplight.status !== 'Completed' && (
          <StyledLabel>{`${differenceInDays(
            new Date(),
            date.parseStopDate(stoplight.created)
          )}d`}</StyledLabel>
        )}
      </NeuContainer>
      <NeuContainer className="h-100 px-0" slot="Location">
        <StyledBoldLabel color="gray-90">
          {location === 'patient' && stoplight.locations?.unit.length > 0
            ? stoplight.locations.unit[0].displayName
            : location === 'employee' &&
              stoplight.locations?.department.length > 0
            ? stoplight.locations?.department[0].displayName
            : 'Unknown'}
        </StyledBoldLabel>
      </NeuContainer>
      <NeuContainer className="h-100 px-0" slot="Stoplight Category">
        <StyledBoldLabel color="gray-90">{stoplight.category}</StyledBoldLabel>
        <StyledLabel color="gray-90">{stoplight.subCategory}</StyledLabel>
      </NeuContainer>
      <NeuContainer className="h-100 px-0" slot="Description">
        <StyledLabel className="text-clamp text-clamp__3" color="gray-90">
          {stoplight.details}
        </StyledLabel>
      </NeuContainer>
      <NeuContainer className="h-100 px-0" slot="Attached Issues">
        <StyledBoldLabel color="gray-90" style={{ paddingLeft: '1rem' }}>
          {stoplight.issueIds?.length}
        </StyledBoldLabel>
      </NeuContainer>
      <NeuContainer className="h-100 px-0" slot="Last Comment">
        {stoplight.latestComment ? (
          <>
            <StyledLabel color="gray-90">
              {stoplight.latestComment.date
                ? format(
                    date.parseStopDate(stoplight.latestComment.date),
                    'MM/dd/yyyy'
                  )
                : 'date'}{' '}
              |{' '}
              {stoplight.latestComment.authorFullName
                ? stoplight.latestComment.authorFullName
                : 'FirstName LastName'}{' '}
            </StyledLabel>
            <StyledLabel className="text-clamp text-clamp__3" color="gray-90">
              {stoplight.latestComment.body ? stoplight.latestComment.body : ''}
            </StyledLabel>
          </>
        ) : (
          'No Comments'
        )}
      </NeuContainer>
      <StoplightColorContainer className="h-100 p-0" slot="Status">
        <NeuIcon
          color={
            stoplight.status === 'Completed'
              ? 'green-60'
              : stoplight.status === 'In Progress'
              ? 'yellow-30'
              : 'red-60'
          }
        >
          circle
        </NeuIcon>
        <StyledBoldLabel
          className="px-1"
          color={
            stoplight.status === 'Completed'
              ? 'green-80'
              : stoplight.status === 'In Progress'
              ? 'yellow-80'
              : 'red-80'
          }
        >
          {stoplight.status}
        </StyledBoldLabel>
      </StoplightColorContainer>
    </NeuTableRow>
  ));

  return (
    <Table
      cols={cols}
      headers={headers}
      rows={rows}
      totalResults={totalResults}
      tableType="Stoplight"
      isStoplightTableEmpty={isStoplightTableEmpty}
      isStoplightTableEmptySearch={isStoplightTableEmptySearch}
      loading={loading}
    />
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  authorizedUser: state.AuthorizedUser?.authorizedUser,
  selectedUnits: state.ConfigReducer.selectedUnits,
  stoplightFilter: state.StoplightReducer.stoplightFilter,
  location: state.UserReducer.userSection,
  locationStoplights: state.StoplightReducer.locationStoplights,
  totalResults: state.StoplightReducer.totalCount,
  currentStoplightPage: state.StoplightReducer.currentStoplightPage,
  loading: state.StoplightReducer.loading
});

export default connect(mapReduxStateToProps)(StoplightTable);
