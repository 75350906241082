import { FC, useCallback, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { format, subDays } from 'date-fns';

import { NeuButton, NeuIcon } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import { RootState } from '../../../redux/store';
import { openModal, setModalType } from '../../../redux/actions/Modal.action';
import { logReportsClick, logTaskClick } from '../../../utils/analyticsHelpers';
import { Filter } from '../../../config/interfaces';

interface FilterBtnProps {
  adminUserFilter: Filter | {};
  feedbackFilter: Filter | {};
  issueFilter: Filter | {};
  roundsReportFilter: Filter | {};
  stoplightFilter: Filter | {};
}
const FilterBtn: FC<FilterBtnProps> = ({
  adminUserFilter,
  feedbackFilter,
  issueFilter,
  roundsReportFilter,
  stoplightFilter
}) => {
  const dispatch = useDispatch();

  const { logTrackingEvent } = useAnalyticsApi();
  const { '*': view } = useParams();

  const chosenFilter: Filter | {} = useMemo(() => {
    switch (view) {
      case 'issues':
        return issueFilter;
      case 'feedback':
        return feedbackFilter;
      case 'stoplight':
        return stoplightFilter;
      case 'patient/rounds':
      case 'employee':
      case 'employee/rounds':
        return roundsReportFilter;
      case 'users':
        return adminUserFilter;
      default:
        return {};
    }
  }, [
    adminUserFilter,
    feedbackFilter,
    issueFilter,
    roundsReportFilter,
    stoplightFilter,
    view
  ]);

  const filterCount = useMemo(() => {
    const cleanFilter = { ...chosenFilter } as Filter;
    const today = new Date();

    const isTaskDateRangeFilterApplied =
      cleanFilter.startDate &&
      cleanFilter.endDate &&
      !(
        cleanFilter.startDate === format(subDays(today, 30), 'yyyy-MM-dd') &&
        cleanFilter.endDate === format(today, 'yyyy-MM-dd')
      );

    const isRoundsReportDateRangeDefault =
      cleanFilter.dateRangeText &&
      cleanFilter.dateRangeText === 'Month to date';

    switch (view) {
      case 'issues':
      case 'feedback':
      case 'stoplight':
        if (!isTaskDateRangeFilterApplied) {
          delete cleanFilter.startDate;
          delete cleanFilter.endDate;
        }
        return (
          Object.values(cleanFilter).filter((val: any) => val).length -
          (isTaskDateRangeFilterApplied ? 1 : 0)
        );
      case 'patient/rounds':
      case 'employee':
      case 'employee/rounds':
        if (Object.values(cleanFilter).filter((val: any) => val).length > 0) {
          delete cleanFilter.startDate;
          delete cleanFilter.endDate;
        }
        return (
          Object.values(cleanFilter).filter((val: any) => val).length -
          (isRoundsReportDateRangeDefault ? 1 : 0)
        );
      case 'users':
        if (Object.values(cleanFilter).filter((val: any) => val).length > 0) {
          delete cleanFilter.startDate;
          delete cleanFilter.endDate;
        }
        return (
          Object.values(cleanFilter).filter((val: any) => val).length -
          (cleanFilter.accessLevel && cleanFilter.accessLevel === 'master'
            ? 1
            : 0)
        );
      default:
        return Object.values(cleanFilter).filter((val: any) => val).length;
    }
  }, [chosenFilter, view]);

  const filterIssues = () => {
    dispatch(setModalType('Filter Issues'));
    dispatch(openModal());
  };

  const filterFeedback = () => {
    dispatch(setModalType('Filter Feedback'));
    dispatch(openModal());
  };

  const filterStoplights = () => {
    dispatch(setModalType('Filter Stoplights'));
    dispatch(openModal());
  };

  const filterAdminUsers = () => {
    dispatch(setModalType('Filter Admin Users'));
    dispatch(openModal());
  };

  const filterRoundsReport = () => {
    dispatch(setModalType('Filter Rounds Report'));
    dispatch(openModal());
  };

  const handleFilterClick = useCallback(() => {
    switch (view) {
      case 'issues':
      case 'feedback':
      case 'stoplight':
        logTrackingEvent(logTaskClick(view, 'filter'));
        break;
      case 'employee':
        logTrackingEvent(logReportsClick('employee-rounds', 'filter'));
        break;
      case 'employee/rounds':
      case 'patient/rounds':
        logTrackingEvent(logReportsClick(view?.replace('/', '-'), 'filter'));
        break;
      default:
        console.log('No view for event');
    }
    return view === 'issues'
      ? filterIssues()
      : view === 'feedback'
      ? filterFeedback()
      : view === 'stoplight'
      ? filterStoplights()
      : view === 'users'
      ? filterAdminUsers()
      : filterRoundsReport();
  }, [view, logTrackingEvent, logReportsClick, logTaskClick]);

  return (
    <div style={{ width: '100%' }}>
      <NeuButton
        id="Filter-Button"
        className="mr-2"
        color="secondary"
        fill={filterCount > 0 ? 'raised' : 'outline'}
        onClick={handleFilterClick}
      >
        {/* comments for adding numbers back in the future */}
        {/* {!isFilterOn && ( */}
        <NeuIcon class="mr-2" feedback="default">
          sort
        </NeuIcon>
        {/* )} */}
        Filter
        {/* {howManyFilters} Filter{howManyFilters && howManyFilters > 1 ? 's' : ''} */}
      </NeuButton>
    </div>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser.authorizedUser,
    modalState: state.ModalReducer.modal,
    issueFilter: state.TaskReducer.issueFilter,
    feedbackFilter: state.TaskReducer.feedbackFilter,
    stoplightFilter: state.StoplightReducer.stoplightFilter,
    roundsReportFilter: state.ReportReducer.roundsReportFilter,
    adminUserFilter: state.AdminReducer.adminUserFilter
  };
};

export default connect(mapReduxStateToProps)(FilterBtn);
