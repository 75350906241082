/**
 * External Imports
 */
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
/**
 * Internal Imports
 */
import SearchHeader from '../../components/searchHeader';
import QuestionBankTable from '../../components/customTables/questionBankTable';
import Loading from '../../components/shared/Loading';

import store, { RootState } from '../../redux/store';
import { setCsvToExport } from '../../redux/actions/Admin.action';
import { editSelectedQuestion } from '../../redux/actions/Template.action';
import { openModal, setModalType } from '../../redux/actions/Modal.action';
/**
 * Global Type Definition Imports
 */
import { CleanQuestion, QuestionList } from '../../config/interfaces';
/**
 * Style Imports
 */
import { QuestionBankContainer } from './styles/AdminViews.styles';

interface QuestionBankProps {
  questionsOfTemplate: QuestionList[];
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  isQuestionListLoading: boolean;
}

const QuestionBank: FC<QuestionBankProps> = ({
  questionsOfTemplate,
  searchTerm,
  setSearchTerm,
  isQuestionListLoading
}) => {
  const dispatch = useDispatch();
  const [csvData, setCsvData] = useState<CleanQuestion[]>([]);

  const handleExportCsv = () => {
    store.dispatch(setCsvToExport(csvData));
    // appInsights.trackEvent({
    //   name: 'Question_List_CSV_Downloaded'
    // });
  };

  const editQuestionOnClick = (id: string) => {
    dispatch(editSelectedQuestion(id));
    dispatch(setModalType('AddQuestion'));
    dispatch(openModal());
  };

  return (
    <QuestionBankContainer>
      <SearchHeader
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleExportCsv={handleExportCsv}
      />
      {!isQuestionListLoading ? (
        <QuestionBankTable
          searchTerm={searchTerm}
          questionsList={questionsOfTemplate}
          handleCsvData={setCsvData}
          editQuestion={editQuestionOnClick}
        />
      ) : (
        <Loading centered={false} />
      )}
    </QuestionBankContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    questionsOfTemplate: state.TemplateReducer.questionsOfTemplate,
    isQuestionListLoading: state.TemplateReducer.isQuestionListLoading
  };
};

export default connect(mapReduxStateToProps)(QuestionBank);
