/**
 * External Imports
 */
import { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { NeuCheckbox, NeuImage, NeuLabel, NeuOption } from '@neutron/react';
import { format, subDays } from 'date-fns';
import { NeuInputChangeEventDetail } from '@neutron/core';
/**
 * Internal Imports
 */
import { RootState } from '../../../redux/store';
import {
  setAdminUserFilter,
  clearAdminUserFilter
} from '../../../redux/actions/Admin.action';
import { openModal, setModalType } from '../../../redux/actions/Modal.action';
import {
  clearRoundsReportFilter,
  setRoundsReportFilter
} from '../../../redux/actions/Report.action';
import {
  clearStoplightFilter,
  setStoplightFilter
} from '../../../redux/actions/Stoplight.action';
import {
  getCategories,
  setFeedbackFilter,
  setIssueFilter,
  clearFeedbackFilter,
  clearIssueFilter
} from '../../../redux/actions/Task.action';
import { getDivisions } from '../../../redux/actions/User.action';
import ModalCard from '../reusableModalPieces/modalCard/ModalCard';
import DateSelectsSection from '../reusableModalPieces/dateSelectsSection/DateSelectsSection';
import PatientSearch from '../reusableModalPieces/patientSearch';
import EmployeeSearch from '../reusableModalPieces/employeeSearch';
import { Dropdown } from '../../shared/dropdowns';
import AdminFilterSection from './adminFilterSection/AdminFilterSection';
import RoundsReportFilterSection from './roundsReportFilterSection';
import { compareObjByStringKey } from '../../../utils/helpers';

import serviceCentralLogoImg from '../../../assets/images/service_central_icon.png';
/**
 * Global Type Definition Imports
 */
import {
  Category,
  Employee,
  Filter,
  Patient
} from '../../../config/interfaces';
/**
 * Style Imports
 */
import {
  InputHeading,
  StatusContainer,
  CheckboxDiv,
  CheckboxLabel
} from './FilterModal.styles';

interface FilterProps {
  filterType: string;
  taskCategories: Category[];
  location: string;
  adminUserFilter: Filter;
  feedbackFilter: Filter;
  issueFilter: Filter;
  stoplightFilter: Filter;
  roundsReportFilter: Filter;
  serviceCentralCategories: string[];
}

const filters: Filter = {
  startDate:
    window.location.pathname.includes('/reports/patient') ||
    window.location.pathname.includes('/reports/employee')
      ? format(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          'yyyy-MM-dd'
        )
      : format(subDays(new Date(), 30), 'yyyy-MM-dd'),
  endDate: format(new Date(), 'yyyy-MM-dd'),
  category: '',
  subCategory: '',
  creator: '',
  createdBy: '',
  patient: '',
  employee: '',
  employeeId: '',
  userId: '',
  complete: false,
  opened: false,
  deferred: false,
  // admin params
  userRole: '',
  accessLevel: '',
  accessPlace: '',
  // including the below parameters for when they exist in the future for issues
  attachedToStoplight: false,
  hasAttachedIssues: false,
  hasUpdate: false,
  dateRangeText: '',
  template: ''
};

const FilterModal: FC<FilterProps> = ({
  filterType,
  taskCategories,
  location,
  adminUserFilter,
  feedbackFilter,
  issueFilter,
  stoplightFilter,
  roundsReportFilter,
  serviceCentralCategories
}) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [filterObject, setFilterObject] = useState<Filter>(filters);
  const [openCatDropdown, setOpenCatDropdown] = useState(false);
  const [openSubcatDropdown, setOpenSubcatDropdown] = useState(false);

  const today = new Date();

  // TODO: Do we need to remove dups [...new Set(feedbackOptions)]
  const categoryNames = useMemo(
    () =>
      [...taskCategories]
        .sort(compareObjByStringKey('name'))
        .map(cat => cat.name),
    [taskCategories]
  );

  const subcategoryNames = useMemo(
    () =>
      [
        ...(taskCategories.find(
          (cat: Category) => filterObject.category === cat.name
        )?.subCategories || [])
      ].sort(),
    [taskCategories, filterObject.category]
  );

  const populateFilter = () => {
    if (Object.keys(roundsReportFilter).length > 0) {
      setFilterObject(roundsReportFilter);
    }
    if (Object.keys(adminUserFilter).length > 0) {
      setFilterObject(adminUserFilter);
    }
    if (filterType === 'issues') {
      setFilterObject({
        ...filterObject,
        ...issueFilter,
        startDate:
          issueFilter.startDate || format(subDays(today, 30), 'yyyy-MM-dd'),
        endDate: issueFilter.endDate || format(today, 'yyyy-MM-dd')
      });
    } else if (filterType === 'feedback') {
      setFilterObject({
        ...filterObject,
        ...feedbackFilter,
        startDate:
          feedbackFilter.startDate || format(subDays(today, 30), 'yyyy-MM-dd'),
        endDate: feedbackFilter.endDate || format(today, 'yyyy-MM-dd')
      });
    } else if (filterType === 'stoplights') {
      setFilterObject({
        ...filterObject,
        ...stoplightFilter,
        startDate:
          stoplightFilter.startDate || format(subDays(today, 30), 'yyyy-MM-dd'),
        endDate: stoplightFilter.endDate || format(today, 'yyyy-MM-dd')
      });
    }
  };

  // get issue types & feedback categories
  useEffect(() => {
    dispatch(getDivisions());
  }, []);

  useEffect(() => {
    // if a filter existing populate it
    populateFilter();
  }, [issueFilter, feedbackFilter, stoplightFilter]);

  // mapping the values out of the categories for feedback & removing duplicates
  useEffect(() => {
    if (
      location &&
      filterType &&
      (filterType === 'feedback' ||
        filterType === 'issues' ||
        filterType === 'stoplights') &&
      (location === 'patient' || location === 'employee')
    ) {
      const params = {
        categoryFor: location,
        categoryType: filterType.replace(/s$/, '')
        // TODO: Filter categories by type ?
        // ...(filterObject.type ? { feedbackType: filterObject.type } : {})
      };
      dispatch(getCategories(params));
    }
  }, [filterType, location]);

  const handleCategorySelect = (value: string) => {
    setFilterObject({
      ...filterObject,
      category: value,
      subCategory: ''
    });
    setOpenCatDropdown(false);
  };

  const handleSubcategorySelect = (value: string) => {
    setFilterObject({ ...filterObject, subCategory: value });
    setOpenSubcatDropdown(false);
  };

  // handling select & date inputs
  const handleInputs = (e: CustomEvent<NeuInputChangeEventDetail>) => {
    const { name, value } = e.target as HTMLNeuInputElement;
    if (value) {
      setFilterObject({
        ...filterObject,
        [name]: value
      });
    }
  };

  // handling select & date inputs
  const handleDropdownChange = useCallback(
    (e: React.BaseSyntheticEvent<MouseEvent>, name: string) => {
      const { value } = e.target;

      // if Master access is chosen on admin filter, input HCA value for access place
      if (name === 'accessLevel' && value === 'master') {
        setFilterObject({ ...filterObject, [name]: value, accessPlace: 'HCA' });
      } else {
        setFilterObject({ ...filterObject, [name]: value });
      }
    },
    [filterObject]
  );

  // handling checkbox states differently because of onChange conflicts
  const handleCheckboxes = (e: React.BaseSyntheticEvent<MouseEvent>) => {
    const { name } = e.target;
    setFilterObject({ ...filterObject, [name]: !filterObject[name] });
  };

  const setPatientSelection = (patient: Patient) => {
    setFilterObject({
      ...filterObject,
      patient: `${patient.firstname} ${patient.lastname}`
    });
  };

  const setEmployeeSelection = (name: string, emp: Employee) => {
    setFilterObject({
      ...filterObject,
      [name]: `${emp.firstName} ${emp.lastName}`,
      [name === 'employee' ? 'employeeId' : 'createdBy']: emp.employeeId
    });
  };

  const setFilters = () => {
    if (filterType === 'issues') {
      dispatch(setIssueFilter(filterObject));
    } else if (filterType === 'feedback') {
      dispatch(setFeedbackFilter(filterObject));
    } else if (filterType === 'stoplights') {
      dispatch(setStoplightFilter(filterObject));
    } else if (filterType === 'adminUsers') {
      dispatch(setAdminUserFilter(filterObject));
    } else if (filterType === 'roundsReport') {
      dispatch(setRoundsReportFilter(filterObject));
    }
  };

  const handleReOpen = () => {
    if (filterType === 'issues') {
      dispatch(setModalType('Filter Issues'));
    } else if (filterType === 'feedback') {
      dispatch(setModalType('Filter Feedback'));
    } else if (filterType === 'stoplights') {
      dispatch(setModalType('Filter Stoplights'));
    } else if (filterType === 'adminUsers') {
      dispatch(setModalType('Filter Admin Users'));
    } else if (filterType === 'roundsReport') {
      dispatch(setModalType('Filter Rounds Report'));
    }
    setFilterObject(filters);
    dispatch(openModal());
  };

  const clearFilterOptions = () => {
    if (filterType === 'stoplights') {
      dispatch(clearStoplightFilter());
    } else if (filterType === 'issues') {
      dispatch(clearIssueFilter());
    } else if (filterType === 'feedback') {
      dispatch(clearFeedbackFilter());
    }
    dispatch(clearAdminUserFilter());
    dispatch(clearRoundsReportFilter());
    setFilterObject(filters);
    handleReOpen();
  };

  const handleRemoveChip = (type: string, empType?: string) => {
    if (type === 'patient') {
      setFilterObject({ ...filterObject, patient: '' });
    } else {
      setFilterObject({
        ...filterObject,
        ...(empType === 'creator'
          ? { creator: '', createdBy: '' }
          : { employee: '', employeeId: '' })
      });
    }
  };

  return (
    <ModalCard
      headerIcon="sort"
      headerTitle="Filter"
      modalRightBtnText="Apply Filters"
      modalRightBtnAction={setFilters}
      modalLeftBtnText="Clear Filters"
      modalLeftBtnAction={clearFilterOptions}
    >
      {filterType === 'adminUsers' ? (
        <AdminFilterSection
          filterValues={filterObject}
          handleDropdownChange={handleDropdownChange}
        />
      ) : filterType === 'roundsReport' ? (
        <RoundsReportFilterSection
          filterValues={filterObject}
          type={pathname.includes('patient') ? 'patient' : 'employee'}
          setFilterValues={setFilterObject}
          handleRemoveChip={handleRemoveChip}
        />
      ) : (
        <>
          <DateSelectsSection
            handleSelects={handleInputs}
            startDate={filterObject.startDate}
            endDate={filterObject.endDate}
            style={{ marginTop: '10px' }}
          />
          <div id="Modal-Category-Select">
            <InputHeading>Category</InputHeading>
            <Dropdown
              classes="pl-0"
              open={openCatDropdown}
              placeholder={
                filterObject.category
                  ? filterObject.category
                  : 'Select a Category'
              }
              width="96%"
              setOpen={setOpenCatDropdown}
            >
              {categoryNames.map((category: string) => (
                <NeuOption
                  className={`dropdown-hover-item${
                    filterObject.category === category ? ' selected' : ''
                  }`}
                  key={category}
                  onClick={() => handleCategorySelect(category)}
                >
                  <NeuLabel className="d-flex align-items-center">
                    {filterType === 'issues' &&
                      serviceCentralCategories.includes(category) && (
                        <NeuImage
                          width="25px"
                          alt="service central logo"
                          src={serviceCentralLogoImg}
                        />
                      )}
                    {category}
                  </NeuLabel>
                </NeuOption>
              ))}
            </Dropdown>
          </div>
          <div id="Modal-Subcategory-Select">
            <InputHeading>Subcategory</InputHeading>
            <Dropdown
              classes="pl-0"
              disabled={!filterObject.category || subcategoryNames.length === 0}
              open={openSubcatDropdown}
              placeholder={
                filterObject.subCategory ||
                (filterObject.category &&
                  subcategoryNames.length === 0 &&
                  'No subcategories') ||
                'Select a Subcategory'
              }
              width="96%"
              setOpen={setOpenSubcatDropdown}
            >
              {subcategoryNames.map((subcategory: string) => (
                <NeuOption
                  className={`dropdown-hover-item${
                    filterObject.subCategory === subcategory ? ' selected' : ''
                  }`}
                  key={subcategory}
                  onClick={() => handleSubcategorySelect(subcategory)}
                >
                  {subcategory}
                </NeuOption>
              ))}
            </Dropdown>
          </div>
          {filterType !== 'stoplights' &&
            (location === 'patient' ? (
              filterType === 'issues' ? (
                <PatientSearch
                  propObject={filterObject}
                  chipVal={filterObject.patient}
                  setPatientSelection={(patient: Patient) =>
                    setPatientSelection(patient)
                  }
                  handleRemovePatient={handleRemoveChip}
                />
              ) : (
                <>
                  <PatientSearch
                    propObject={filterObject}
                    chipVal={filterObject.patient}
                    setPatientSelection={(patient: Patient) =>
                      setPatientSelection(patient)
                    }
                    handleRemovePatient={handleRemoveChip}
                  />
                  <EmployeeSearch
                    val={filterObject.employee}
                    chipVal={filterObject.employee}
                    setEmployeeSelection={(emp: Employee) =>
                      setEmployeeSelection('employee', emp)
                    }
                    handleRemoveEmployee={(type: string) =>
                      handleRemoveChip(type, 'employee')
                    }
                  />
                </>
              )
            ) : (
              <EmployeeSearch
                val={filterObject.employee}
                chipVal={filterObject.employee}
                setEmployeeSelection={(emp: Employee) =>
                  setEmployeeSelection('employee', emp)
                }
                handleRemoveEmployee={(type: string) =>
                  handleRemoveChip(type, 'employee')
                }
              />
            ))}
          <InputHeading>Created By</InputHeading>
          <EmployeeSearch
            val={filterObject.creator}
            chipVal={filterObject.creator}
            setEmployeeSelection={(emp: Employee) =>
              setEmployeeSelection('creator', emp)
            }
            handleRemoveEmployee={(type: string) =>
              handleRemoveChip(type, 'creator')
            }
            showLabel={false}
          />
          <InputHeading>Status</InputHeading>
          <StatusContainer id="Filter-Modal-Status-Container">
            <CheckboxDiv>
              <NeuCheckbox
                name="opened"
                color="primary"
                checked={filterObject.opened}
                onClick={handleCheckboxes}
              />
              <CheckboxLabel>
                {filterType === 'stoplights' ? 'In Progress' : 'Opened'}
              </CheckboxLabel>
            </CheckboxDiv>
            <CheckboxDiv>
              <NeuCheckbox
                id="Filter-Modal-Status-Complete-Checkbox"
                name="complete"
                color="primary"
                checked={filterObject.complete}
                onClick={handleCheckboxes}
              />
              <CheckboxLabel>Completed</CheckboxLabel>
            </CheckboxDiv>
            {filterType === 'stoplights' && (
              <CheckboxDiv>
                <NeuCheckbox
                  id="Filter-Modal-Status-Deferred-Checkbox"
                  name="deferred"
                  color="primary"
                  checked={filterObject.deferred}
                  onClick={handleCheckboxes}
                />
                <CheckboxLabel>Deferred</CheckboxLabel>
              </CheckboxDiv>
            )}
          </StatusContainer>
          {filterType !== 'feedback' ? (
            <>
              <InputHeading>Attachments & Updates</InputHeading>
              <StatusContainer>
                {filterType === 'stoplights' ? (
                  <CheckboxDiv>
                    <NeuCheckbox
                      id="Filter-Modal-Status-Has-Issues-Checkbox"
                      name="hasAttachedIssues"
                      color="primary"
                      checked={filterObject.hasAttachedIssues}
                      onClick={handleCheckboxes}
                    />
                    <CheckboxLabel>Has attached issues</CheckboxLabel>
                  </CheckboxDiv>
                ) : (
                  <CheckboxDiv>
                    <NeuCheckbox
                      id="Filter-Modal-Status-Attached-To-Stoplight-Checkbox"
                      name="attachedToStoplight"
                      color="primary"
                      checked={filterObject.attachedToStoplight}
                      onClick={handleCheckboxes}
                    />
                    <CheckboxLabel>Attached to Stoplight</CheckboxLabel>
                  </CheckboxDiv>
                )}

                <CheckboxDiv>
                  <NeuCheckbox
                    id="Filter-Modal-Status-Has-An-Update-Checkbox"
                    name="hasUpdate"
                    color="primary"
                    checked={filterObject.hasUpdate}
                    onClick={handleCheckboxes}
                  />
                  <CheckboxLabel>Has an update</CheckboxLabel>
                </CheckboxDiv>
              </StatusContainer>
            </>
          ) : (
            ''
          )}
        </>
      )}
    </ModalCard>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    taskCategories: state.TaskReducer.categories,
    adminUserFilter: state.AdminReducer.adminUserFilter,
    location: state.UserReducer.userSection,
    feedbackFilter: state.TaskReducer.feedbackFilter,
    issueFilter: state.TaskReducer.issueFilter,
    stoplightFilter: state.StoplightReducer.stoplightFilter,
    roundsReportFilter: state.ReportReducer.roundsReportFilter,
    serviceCentralCategories: state.ConfigReducer.serviceCentralCategories
  };
};

export default connect(mapReduxStateToProps)(FilterModal);
