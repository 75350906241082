/**
 * * React Imports
 */
import { FC, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { connect } from 'react-redux';

import {
  NeuTable,
  NeuTableRow,
  NeuTableHeading,
  NeuTableBody,
  NeuLabel,
  NeuIcon,
  NeuContainer,
  NeuCheckbox
} from '@neutron/react';
/**
 * * Component Imports
 */
import store, { RootState } from '../../../redux/store';
import {
  getTemplateById,
  getTemplateDoc,
  setChosenTemplate,
  setQuestionsOfTemplate
} from '../../../redux/actions/Template.action';
import { AuthUserProps, Template } from '../../../config/interfaces';
/**
 * * Style Imports
 */
import { ActiveText } from '../areasTable/AreasTable.styles';

interface TemplateTreeTableProps {
  treeData: any[];
  level: string;
  handleTableRowClick: (data: any, level: string) => void;
  roundingType: string;
  setEditTemplateFlag: Dispatch<SetStateAction<boolean>>;
  selectedTemplate: Template;
  authUser: AuthUserProps;
}

/**
 * Table component for Admin -> Templates page filter by type of template
 * @param props {Object} - Division list by template and if it is published/not published, when the template was last update, and by who it was updated
 * @returns React Functional Component (Template Tree Table)
 */
const TemplateTreeTable: FC<TemplateTreeTableProps> = ({
  treeData,
  level,
  handleTableRowClick,
  roundingType,
  setEditTemplateFlag,
  selectedTemplate,
  authUser
}) => {
  /**
   * Local state for array of dynamic status properties
   */
  const [listToDisplay, setListToDisplay] = useState<any[]>([]);
  const [sortStatus, setSortStatus] = useState<any>({ name: 'asc' });
  const [active, setActive] = useState<string>('name');

  /**
   * Use effect for assigning tree data to list (division/facility data)
   */
  useEffect(() => {
    setListToDisplay(treeData);
  }, [treeData]);

  /**
   * Sort by key if selected
   * @param key - table header
   */
  const sortByHeading = (key: string) => {
    const stateCopy = listToDisplay?.length ? [...listToDisplay] : [];

    let sorted: any = [];
    if (sortStatus[key] === 'desc') {
      // already sorted descending
      const obj = {
        [key]: 'asc'
      };
      sorted = stateCopy.sort((a: any, b: any) =>
        a.data[key] > b.data[key] ? 1 : -1
      );
      setSortStatus({ ...sortStatus, ...obj });
    }
    if (sortStatus[key] === 'asc') {
      // already sorted ascending
      const obj = {
        [key]: 'desc'
      };
      sorted = stateCopy.sort((a: any, b: any) =>
        a.data[key] > b.data[key] ? -1 : 1
      );
      setSortStatus({ ...sortStatus, ...obj });
    }
    if (!sortStatus[key]) {
      // not sorted yet
      const obj = {
        [key]: 'asc'
      };
      sorted = stateCopy.sort((a: any, b: any) =>
        a.data[key] > b.data[key] ? 1 : -1
      );
      setSortStatus({ ...sortStatus, ...obj });
    }
    setActive(key);
    setListToDisplay(sorted);
  };

  /**
   * If published check box is selected/deselected update changes to the API
   * @param item - current line item - division name, published(?), updated when/who
   */
  const handlePublishedChange = (item: any) => {
    const isPublished = !item.data.isPublished;

    store.dispatch(
      getTemplateById({
        templateId: item.data.templateConfigId,
        publishToggle: true,
        isPublished,
        templateLevel: item.data.level,
        templateLevelId: item.data.levelId
      })
    );
  };

  /**
   * Edit template
   * @param item - current template
   */
  const handleEdit = (item: any) => {
    const updatedSelectedTemplate = {
      ...selectedTemplate,
      templateLevel: item.data.level,
      templateLevelId: item.data.levelId
    };

    store.dispatch(setChosenTemplate(updatedSelectedTemplate));
    store.dispatch(setQuestionsOfTemplate([]));
    // appInsights.trackEvent({
    //   name: 'Template_Changed'
    // });

    store.dispatch(getTemplateDoc({ templateKey: item.data.templateKey }));
    setEditTemplateFlag(true);
  };

  /**
   * Iterate through the listToDisplay content array and return line item for each
   */
  return (
    <NeuTable style={{ marginBottom: '50px' }}>
      <NeuTableRow
        style={{ backgroundColor: 'white', height: '56px' }}
        columns={`{'${level}':'25%','Published':'25%', 'Last Updated':'15%', 'Updated By':'15%', 'Configured':'10%','edit':'10%'}`}
        header
      >
        <NeuTableHeading
          slot={level}
          onClick={() => sortByHeading('name')}
          icon={
            !(active === 'name')
              ? 'asc'
              : sortStatus.name === 'asc'
              ? 'desc'
              : 'asc'
          }
          active={active === 'name'}
        >
          {level}
        </NeuTableHeading>
        {roundingType === 'patient' && (
          <NeuTableHeading
            slot="Published"
            onClick={() => sortByHeading('isPublished')}
            icon={
              !(active === 'isPublished')
                ? 'asc'
                : sortStatus.isPublished === 'asc'
                ? 'desc'
                : 'asc'
            }
            active={active === 'isPublished'}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              Published
              {/* //// MAY BE UNCOMMENTED ON PHASE 2 DEVELOPMENT ///////
             <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: 12,
                justifyContent: 'space-between'
              }}
            >
              <NeuCheckbox style={{ marginRight: 5 }} />
              <NeuLabel style={{}}>All {level}</NeuLabel>
            </div> */}
            </div>
          </NeuTableHeading>
        )}

        <NeuTableHeading
          slot="Last Updated"
          onClick={() => sortByHeading('lastUpdated')}
          icon={
            !(active === 'lastUpdated')
              ? 'asc'
              : sortStatus.lastUpdated === 'asc'
              ? 'desc'
              : 'asc'
          }
          active={active === 'lastUpdated'}
        >
          Last Updated
        </NeuTableHeading>
        <NeuTableHeading
          slot="Updated By"
          onClick={() => sortByHeading('lastUpdatedByName')}
          icon={
            !(active === 'lastUpdatedByName')
              ? 'asc'
              : sortStatus.lastUpdatedByName === 'asc'
              ? 'desc'
              : 'asc'
          }
          active={active === 'lastUpdatedByName'}
        >
          Updated By
        </NeuTableHeading>
        <NeuTableHeading
          slot="Configured"
          onClick={() => sortByHeading('hasConfig')}
          icon={
            !(active === 'hasConfig')
              ? 'asc'
              : sortStatus.hasConfig === 'asc'
              ? 'desc'
              : 'asc'
          }
          active={active === 'hasConfig'}
        >
          Configured
        </NeuTableHeading>
      </NeuTableRow>

      <NeuTableBody>
        {listToDisplay?.map((item: any) => {
          return (
            <NeuTableRow
              columns={`{'${level}':'25%', 'Published':'25%', 'Last Updated':'15%', 'Updated By':'15%', 'Configured':'10%','edit':'10%'}`}
              size="large"
              key={item.data.levelId}
              onClick={() => {
                handleTableRowClick(item, level);
              }}
            >
              <NeuLabel
                style={{
                  whiteSpace: 'normal',
                  fontWeight: 700,
                  color: ' #206DA3'
                }}
                slot={level}
              >
                {item.data.name}
              </NeuLabel>
              {roundingType === 'patient' && (
                <NeuContainer className="h-100 px-0" slot="Published">
                  <NeuLabel className="d-flex align-items-center">
                    <NeuCheckbox
                      color="primary"
                      checked={item.data.isPublished === true}
                      onClick={e => {
                        e.stopPropagation();
                        handlePublishedChange(item);
                      }}
                    />
                    <ActiveText>Published</ActiveText>
                  </NeuLabel>
                </NeuContainer>
              )}

              <NeuLabel style={{ whiteSpace: 'normal' }} slot="Last Updated">
                <div>
                  {item.data.lastUpdated
                    ? new Date(item.data.lastUpdated)
                        .toLocaleString([], {
                          day: '2-digit',
                          month: '2-digit',
                          year: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit'
                        })
                        .replace(',', '')
                    : ''}
                </div>
              </NeuLabel>
              <NeuLabel slot="Updated By" style={{ whiteSpace: 'normal' }}>
                <div>
                  <div>
                    {item.data.lastUpdated && item.data.lastUpdatedByName
                      ? item.data.lastUpdatedByName
                      : item.data.lastUpdated && !item.data.lastUpdatedByName
                      ? 'Unknown'
                      : ''}
                  </div>
                  <div>
                    {item.data.lastUpdated && item.data.lastUpdatedBy
                      ? item.data.lastUpdatedBy
                      : item.data.lastUpdated && !item.data.lastUpdatedBy
                      ? 'Unknown'
                      : ''}
                  </div>
                </div>
              </NeuLabel>
              <NeuLabel slot="Configured">
                <div>{item.data.hasConfig ? 'Yes' : 'No'}</div>
              </NeuLabel>
              {(level.toLowerCase() === authUser.access.accessLevel ||
                authUser.access.accessLevel === 'division' ||
                authUser.access.accessLevel === 'master' ||
                level === 'Unit') && (
                <NeuLabel
                  onClick={() => handleEdit(item)}
                  slot="edit"
                  style={{ display: 'flex', fontWeight: 700, color: '#206DA3' }}
                >
                  <NeuIcon class="mr-2" feedback="default">
                    edit
                  </NeuIcon>
                  Edit
                </NeuLabel>
              )}
            </NeuTableRow>
          );
        })}
      </NeuTableBody>
    </NeuTable>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    selectedTemplate: state.TemplateReducer.selectedTemplate,
    authUser: state.AuthorizedUser.authorizedUser
  };
};

export default connect(mapReduxStateToProps)(TemplateTreeTable);
