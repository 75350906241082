import { FC } from 'react';
import { connect, useDispatch } from 'react-redux';

import { NeuButton } from '@neutron/react';

import { RootState } from '../../../../redux/store';
import { completeTask } from '../../../../redux/actions/Task.action';

import { StyledCheckIcon } from './MarkCompleteBtn.styles';

interface ICompleteProps {
  edit?: boolean;
  taskId?: string;
  taskType: string;
}

const MarkCompleteBtn: FC<ICompleteProps> = ({ edit, taskId, taskType }) => {
  const dispatch = useDispatch();
  return (
    <NeuButton
      id="Mark-Complete-Button"
      className="d-flex"
      type="button"
      color="green-60"
      fill="outline"
      size="small"
      onClick={e => {
        e.stopPropagation();
        if (taskId)
          dispatch(completeTask({ taskId, complete: true, edit, taskType }));
      }}
    >
      <StyledCheckIcon className="mr-1" color="plain-0" small>
        check
      </StyledCheckIcon>
      Mark Complete
    </NeuButton>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  taskData: state.ModalReducer.modalData
});

export default connect(mapReduxStateToProps)(MarkCompleteBtn);
