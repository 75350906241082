/**
 * External Imports
 */
import { FC, useCallback, useMemo, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { NeuContainer, NeuTableRow } from '@neutron/react';
/**
 * Internal Imports
 */
import Table from '../../shared/table';
import { MarkCompleteBtn } from '../../shared/buttons';

import store, { RootState } from '../../../redux/store';
import {
  openModal,
  setModalType,
  setModalAction
} from '../../../redux/actions/Modal.action';
import {
  selectDataForEdit,
  clearFeedbackFilter,
  setTaskSort
} from '../../../redux/actions/Task.action';

import { useFeedbackApi } from '../../../utils/api';
import { useDebounceValue } from '../../../utils/debouncers';
import { date, text, abbreviatedName } from '../../../utils/helpers';
/**
 * Global Type Definition Imports
 */
import { AuthUserProps, Filter, Feedback } from '../../../config/interfaces';
/**
 * Style Imports
 */
import {
  StyledBoldMoreLabel,
  StyledPlusIcon,
  StyledSpan
} from './FeedbackTable.styles';
import {
  StyledBoldLabel,
  StyledLabel,
  StyledNoWrapLabel,
  StyledTableHeading
} from '../../shared/table/Table.styles';
import { useUserRolesRef } from '../../../services/UserRoles';

interface IFeedbackTableProps {
  authorizedUser: AuthUserProps;
  location: string;
  units: { unitId: string; unit: string }[];
  searchTerm: string;
  selectedUnits: { id: string; unit: string }[];
  storeFeedback: Feedback[];
  loading?: boolean;
  feedbackFilter: Filter;
  totalCount: number;
  currentFeedbackPage: number;
  selectedDelegate: string;
}

const FeedbackTable: FC<IFeedbackTableProps> = ({
  authorizedUser,
  location,
  units,
  searchTerm,
  selectedUnits,
  storeFeedback,
  loading,
  feedbackFilter,
  totalCount,
  currentFeedbackPage,
  selectedDelegate
}) => {
  const dispatch = useDispatch();
  const [feedback, setFeedback] = useState<Feedback[]>([]);
  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('desc');
  const [sortKey, setSortKey] = useState<string>('created');
  const [active, setActive] = useState<string>('Created');

  const { isEnterpriseAdmin } = useUserRolesRef();

  const dbSearchTerm = useDebounceValue(searchTerm.trim(), 350);

  const isFeedbackTableEmpty = useMemo(
    () => !dbSearchTerm && storeFeedback.length === 0,
    [dbSearchTerm, storeFeedback]
  );

  const isFeedbackTableSearchEmpty = useMemo(
    () => dbSearchTerm && storeFeedback.length === 0,
    [dbSearchTerm, storeFeedback]
  );

  // Reset page when navigate away
  useEffect(() => {
    return () => {
      dispatch(clearFeedbackFilter());
      dispatch(setTaskSort({ sortKey: 'created', sortDir: 'desc' }));
    };
  }, []);

  useEffect(() => {
    const feedbackAction = useFeedbackApi({
      authorizedUser,
      currentFeedbackPage,
      searchTerm: dbSearchTerm && dbSearchTerm.length > 2 ? dbSearchTerm : '',
      feedbackFilter,
      sortKey,
      sortDir,
      selectedDelegate,
      selectedUnits
    });
    store.dispatch(feedbackAction);
  }, [
    authorizedUser,
    currentFeedbackPage,
    dbSearchTerm,
    feedbackFilter,
    selectedDelegate,
    selectedUnits,
    sortKey,
    sortDir
  ]);

  useEffect(() => {
    setFeedback(storeFeedback);
  }, [storeFeedback]);

  useEffect(() => {
    dispatch(setTaskSort({ sortKey, sortDir }));
  }, [sortDir, sortKey]);

  const sortByHeading = (key: string, column: string) => {
    if (key === sortKey && sortDir === 'asc') {
      setSortDir('desc');
    } else {
      setSortDir('asc');
    }
    setActive(column);
    setSortKey(key);
  };

  const openFeedback = useCallback(
    (e: React.BaseSyntheticEvent<MouseEvent>, fbRow: Feedback) => {
      if (e.target.id !== 'btn-complete') {
        dispatch(setModalType('Feedback'));
        dispatch(selectDataForEdit(fbRow));
        // Edit modal
        if (
          !fbRow.completed &&
          (isEnterpriseAdmin ||
            (fbRow.userId.toLowerCase() ===
              authorizedUser?.hcaid.toLowerCase() &&
              fbRow.taskDate &&
              !date.isOverOneDayOld(fbRow.taskDate)))
        ) {
          dispatch(setModalAction('EDIT'));
          dispatch(openModal());
          // Partial edit modal
        } else if (
          !fbRow.completed &&
          fbRow.userId.toLowerCase() === authorizedUser?.hcaid.toLowerCase() &&
          fbRow.taskDate &&
          date.isOverOneDayOld(fbRow.taskDate)
        ) {
          dispatch(setModalAction('PARTIAL-EDIT'));
          dispatch(openModal());
        } else {
          // View modal
          dispatch(setModalAction('VIEW'));
          dispatch(openModal());
        }
      }
    },
    [authorizedUser]
  );

  const colsForPatient =
    '{"Created":"10%","Location":"10%","Type":"10%","Category":"17%","Description":"17%","Employee":"12%","Patient":"12%","Status": "12%"}';
  const colsForEmployee =
    '{"Created":"10%","Location":"13%","Type":"12%","Category":"18%","Description":"22%","Employee":"13%","Status": "12%"}';

  const headers: JSX.Element[] = [
    <StyledTableHeading
      id="Feedback-Table-Created-Column"
      key="Created"
      slot="Created"
      icon={
        !(active === 'Created') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('created', 'Created')}
      active={active === 'Created'}
    >
      Created
    </StyledTableHeading>,
    <StyledTableHeading
      id="Feedback-Table-Location-Column"
      key="Location"
      slot="Location"
      icon={
        !(active === 'Location') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('location', 'Location')}
      active={active === 'Location'}
    >
      {location === 'patient' ? 'Location' : 'Department'}
    </StyledTableHeading>,
    <StyledTableHeading
      id="Feedback-Table-Type-Column"
      key="Type"
      slot="Type"
      icon="none"
    >
      Type
    </StyledTableHeading>,
    <StyledTableHeading
      id="Feedback-Table-Category-Column"
      key="Category"
      slot="Category"
      icon={
        !(active === 'Category') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('category', 'Category')}
      active={active === 'Category'}
    >
      Category/Subcategory
    </StyledTableHeading>,
    <StyledTableHeading
      id="Feedback-Table-Description-Column"
      key="Description"
      slot="Description"
      icon="none"
    >
      Description
    </StyledTableHeading>,
    <StyledTableHeading
      id="Feedback-Table-Employee-Column"
      key="Employee"
      slot="Employee"
      icon={
        !(active === 'Employee') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('employeeName', 'Employee')}
      active={active === 'Employee'}
    >
      Employee
    </StyledTableHeading>
  ];

  if (location === 'patient') {
    headers.push(
      <StyledTableHeading
        id="Feedback-Table-Patient-Column"
        key="Patient"
        slot="Patient"
        icon={
          !(active === 'Patient') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
        }
        onClick={() => sortByHeading('patientName', 'Patient')}
        active={active === 'Patient'}
      >
        Patient
      </StyledTableHeading>
    );
  }
  headers.push(
    <StyledTableHeading
      id="Feedback-Table-Status-Column"
      key="Status"
      slot="Status"
      icon={!(active === 'Status') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'}
      onClick={() => sortByHeading('status', 'Status')}
      active={active === 'Status'}
    >
      Status
    </StyledTableHeading>
  );

  const rows = feedback?.map((fb: Feedback) => (
    <NeuTableRow
      id={`Feeback-Row-${fb.taskId}`}
      key={fb.taskId}
      columns={location === 'patient' ? colsForPatient : colsForEmployee}
      size="large"
      onClick={(e: React.BaseSyntheticEvent<MouseEvent>) => openFeedback(e, fb)}
      color={fb.completed ? 'gray-0' : 'plain-0'}
    >
      <NeuContainer className="h-100 px-0" slot="Created">
        <StyledBoldLabel>{fb.userFullName}</StyledBoldLabel>
        <StyledLabel>
          {fb.taskDate ? date.dayOfYrFull(fb.taskDate) : ''}
        </StyledLabel>
      </NeuContainer>
      {location === 'patient' ? (
        <NeuContainer className="h-100 px-0" slot="Location">
          {units
            .filter(unit => unit.unitId === fb.unitId)
            .map(unit => (
              <StyledBoldLabel key={unit.unitId} color="gray-90">
                {unit.unit}
              </StyledBoldLabel>
            ))}
          <StyledLabel>
            {fb.room ? `${fb.room}${fb.bed || ''}` : ''}
          </StyledLabel>
        </NeuContainer>
      ) : (
        <NeuContainer className="h-100 px-0" slot="Location">
          <StyledBoldLabel color="gray-90">
            {fb?.department ? fb.department : 'Unknown'}
          </StyledBoldLabel>
        </NeuContainer>
      )}
      <NeuContainer className="h-100 px-0" slot="Type">
        <StyledLabel color="gray-90">
          {text.capFirst(fb.taskSubtype)}
        </StyledLabel>
      </NeuContainer>
      <NeuContainer className="h-100 px-0" slot="Category">
        {'\n'}
        {fb.taskCategory.length > 0 ? (
          <>
            <StyledNoWrapLabel>
              <StyledBoldLabel>
                {fb.taskCategory[0] && fb.taskCategory[0]}
              </StyledBoldLabel>
            </StyledNoWrapLabel>
            {fb.taskCategory[1] && (
              <NeuContainer className="px-0">
                <StyledNoWrapLabel>
                  <StyledBoldLabel>
                    {fb.taskCategory[1] && fb.taskCategory[1]}
                  </StyledBoldLabel>
                </StyledNoWrapLabel>
                {fb.taskCategory.length > 2 && (
                  <StyledBoldMoreLabel color="primary">
                    <StyledSpan> (</StyledSpan>
                    <StyledPlusIcon feedback="default" color="plain">
                      add
                    </StyledPlusIcon>
                    <StyledSpan>{fb.taskCategory.slice(2).length})</StyledSpan>
                  </StyledBoldMoreLabel>
                )}
              </NeuContainer>
            )}
          </>
        ) : (
          <>
            <StyledBoldLabel color="gray-90">{fb.category}</StyledBoldLabel>
            <StyledLabel color="gray-90">
              {fb.subCategory ? fb.subCategory : ''}
            </StyledLabel>
          </>
        )}
      </NeuContainer>
      <NeuContainer className="h-100 px-0" slot="Description">
        <StyledLabel color="gray-90" className="text-clamp text-clamp__3">
          {fb.description}
        </StyledLabel>
      </NeuContainer>
      <NeuContainer className="h-100 px-0" slot="Employee">
        <StyledBoldLabel color="gray-90">
          {fb.employeeFullName ? fb.employeeFullName : ''}
        </StyledBoldLabel>
        <StyledLabel>{fb.employeeId ? fb.employeeId : ''}</StyledLabel>
      </NeuContainer>
      {location === 'patient' && (
        <StyledBoldLabel color="gray-90" slot="Patient">
          {fb.roundingType === 'patient' &&
          fb.roundId &&
          (fb.patientFirstName === '' || fb.patientLastName === '')
            ? 'Unknown'
            : abbreviatedName(fb.patientFirstName, fb.patientLastName)}
        </StyledBoldLabel>
      )}
      <NeuContainer className="h-100 px-0" slot="Status">
        <StyledBoldLabel className="mb-2" color="gray-90">
          {fb.completed ? 'Completed' : 'Opened'}
        </StyledBoldLabel>
        {fb.completed && fb.completedDate ? ( // Add check for completedDate some data coming back with null
          <StyledLabel>{date.dayOfYrFull(fb.taskDate)}</StyledLabel>
        ) : !fb.completed &&
          (isEnterpriseAdmin ||
            fb.userId.toLowerCase() === authorizedUser?.hcaid.toLowerCase()) ? (
          <MarkCompleteBtn taskId={fb.taskId} taskType="feedback" />
        ) : null}
      </NeuContainer>
    </NeuTableRow>
  ));

  return (
    <Table
      cols={location === 'patient' ? colsForPatient : colsForEmployee}
      headers={headers}
      rows={rows}
      tableType="Feedback"
      totalResults={totalCount}
      isFeedbackTableEmpty={isFeedbackTableEmpty}
      isFeedbackTableEmptySearch={isFeedbackTableSearchEmpty}
      loading={loading}
    />
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  authorizedUser: state.AuthorizedUser?.authorizedUser,
  units: state.ConfigReducer.units,
  selectedUnits: state.ConfigReducer.selectedUnits,
  storeFeedback: state.TaskReducer.feedback,
  loading: state.TaskReducer.loading,
  feedbackFilter: state.TaskReducer.feedbackFilter,
  location: state.UserReducer.userSection,
  totalCount: state.TaskReducer.totalCount,
  currentFeedbackPage: state.TaskReducer.currentFeedbackPage,
  selectedDelegate: state.EmployeeReducer.selectedDelegatedEmployee.hcaid
});

export default connect(mapReduxStateToProps)(FeedbackTable);
