import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { sub } from 'date-fns';

import { NeuButton, NeuIcon, NeuSpinner } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import store, { RootState } from '../../../../redux/store';
import { clearCsvToExport } from '../../../../redux/actions/Admin.action';
import { getAllStoplightByLocation } from '../../../../redux/actions/Stoplight.action';
import {
  getAllFacilityFeedback,
  getAllFacilityIssues,
  getAllFeedbackListByFacilityId,
  getAllIssuesListByFacility
} from '../../../../redux/actions/Task.action';

import { logTaskClick } from '../../../../utils/analyticsHelpers';
import { date } from '../../../../utils/helpers';
import {
  AuthUserProps,
  Employee,
  Filter,
  GetTaskParams
} from '../../../../config/interfaces';

interface Units {
  active: string;
  facilityId: string;
  id?: string;
  lastUpdated: string;
  lastUpdatedBy: string;
  locType: string;
  unit: string;
  unitId: string;
  userFullName: string;
}

interface DownloadCSVProps {
  authorizedUser: AuthUserProps;
  dataToCsvExport?: any[];
  employeesToRound: [];
  isLoading?: boolean;
  issueFilter: Filter;
  feedbackFilter: Filter;
  fileNamePrefix: string;
  location: string;
  selectedDelegate: string;
  selectedUnits: { id: string; unit: string }[];
  sortDirStoplight: string;
  sortKeyStoplight: string;
  sortDirTask: string;
  sortKeyTask: string;
  stoplightFilter: Filter;
  stoplightSearchQuery: string;
  taskSearchQuery: string;
  units: Units[];
  downloadStoplight?: () => void;
}

const DownloadBtn: FC<DownloadCSVProps> = ({
  authorizedUser,
  dataToCsvExport,
  employeesToRound,
  isLoading,
  issueFilter,
  feedbackFilter,
  fileNamePrefix,
  location,
  selectedDelegate,
  selectedUnits,
  sortDirStoplight,
  sortKeyStoplight,
  sortDirTask,
  sortKeyTask,
  stoplightFilter,
  stoplightSearchQuery,
  taskSearchQuery,
  units,
  downloadStoplight
}) => {
  const dispatch = useDispatch();
  const [csvData, setCsvData] = useState<any[]>([]);
  const [csvHeaders, setCsvHeaders] = useState<
    { label: string; key: string }[] | string[]
  >([]);

  const [csvFileName, setCsvFileName] = useState<string>('');

  const { logTrackingEvent } = useAnalyticsApi();
  const { '*': view } = useParams();

  const csvLinkRef = useRef<
    (CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }) | null
  >(null);
  const firstUpdate: { current: boolean } = useRef(true); // to detect first render

  const selectedUnitIds = useMemo(
    () => selectedUnits.map(item => item.id),
    [selectedUnits]
  );

  const downloadAllIssues = useCallback(() => {
    const filterData = {
      sortBy: sortKeyTask,
      sortOrder: sortDirTask,
      query: taskSearchQuery
    } as GetTaskParams;
    if (location === 'patient') {
      filterData.facilityId = authorizedUser.facilityId;
      filterData.units = selectedUnits.map(val => val.id).join(',');
    } else {
      filterData.employeeIds = employeesToRound
        .map((val: Employee) => val.hcaid)
        .join(',');
    }

    if (Object.keys(issueFilter).length > 0) {
      const {
        startDate,
        endDate,
        category,
        subCategory,
        createdBy,
        employeeId,
        complete,
        opened,
        attachedToStoplight,
        hasUpdate,
        patient
      } = issueFilter;
      const statuses = [];
      if (complete) {
        statuses.push('completed');
      }
      if (opened) {
        statuses.push('opened');
      }
      if (attachedToStoplight) {
        filterData.attachedToStoplight = attachedToStoplight;
      }
      if (hasUpdate) {
        filterData.hasComment = hasUpdate;
      }
      filterData.startDate = startDate
        ? date.formatFilterStartDate(startDate)
        : date.formatFilterStartDate(
            date.convertToDayOfYear(sub(new Date(), { days: 30 }))
          );
      filterData.endDate = endDate
        ? date.formatFilterEndDate(endDate)
        : date.formatFilterEndDate(date.convertToDayOfYear(new Date()));

      if (location === 'patient') {
        filterData.category = category;
        filterData.subCategory = subCategory;
        filterData.createdBy = createdBy;
        filterData.patientName = patient;
        filterData.status = statuses;

        return dispatch(
          getAllFacilityIssues({
            location,
            filterData
          })
        );
      }
      if (selectedDelegate === authorizedUser.facilityId) {
        delete filterData.employeeIds;
        return store.dispatch(
          dispatch(
            getAllIssuesListByFacility({
              location,
              filterData: {
                ...filterData,
                facilityId: authorizedUser.facilityId,
                category,
                subCategory,
                createdBy,
                employeeId,
                status: statuses
              }
            })
          )
        );
      }
      filterData.category = category;
      filterData.subCategory = subCategory;
      filterData.createdBy = createdBy;
      filterData.employeeId = employeeId;
      filterData.status = statuses;
      filterData.userId = selectedDelegate || authorizedUser.hcaid;
      return dispatch(
        getAllFacilityIssues({
          location,
          filterData
        })
      );
    }

    filterData.startDate = date.formatFilterStartDate(
      date.convertToDayOfYear(sub(new Date(), { days: 30 }))
    );

    if (location === 'patient') {
      return dispatch(
        getAllFacilityIssues({
          location,
          filterData: {
            ...filterData,
            facilityId: authorizedUser.facilityId,
            units: selectedUnits.map(val => val.id).join(',')
          }
        })
      );
    }
    if (selectedDelegate === authorizedUser.facilityId) {
      return store.dispatch(
        dispatch(
          getAllIssuesListByFacility({
            location,
            filterData: {
              ...filterData,
              facilityId: authorizedUser.facilityId
            }
          })
        )
      );
    }
    return dispatch(
      getAllFacilityIssues({
        location,
        filterData: {
          ...filterData,
          userId: selectedDelegate || authorizedUser.hcaid,
          employeeIds: employeesToRound
            .map((val: Employee) => val.hcaid)
            .join(',')
        }
      })
    );
  }, [
    authorizedUser,
    location,
    selectedDelegate,
    selectedUnitIds,
    issueFilter,
    sortDirTask,
    sortKeyTask,
    taskSearchQuery
  ]);

  const downloadAllFeedback = useCallback(() => {
    let filterData = {
      sortBy: sortKeyTask,
      sortOrder: sortDirTask,
      startDate: date.formatFilterStartDate(
        date.convertToDayOfYear(sub(new Date(), { days: 30 }))
      ),
      query: taskSearchQuery
    } as GetTaskParams;
    if (location === 'patient') {
      const selectedUnitsIds = selectedUnits.map(val => val.id);
      filterData.facilityId = authorizedUser.facilityId;
      filterData.units = selectedUnitsIds.join(',');
      filterData.employeeId = '';
    } else {
      const hcaIds = employeesToRound.map((val: Employee) => val.hcaid);
      filterData.employeeIds = hcaIds.join(',');
      filterData.searchEmployeeId = '';
    }
    if (Object.keys(feedbackFilter).length > 0) {
      const {
        startDate,
        endDate,
        category,
        subCategory,
        createdBy,
        patient,
        employeeId,
        complete,
        opened
      } = feedbackFilter;
      const statuses = [];
      if (complete) {
        statuses.push('completed');
      }
      if (opened) {
        statuses.push('opened');
      }

      if (location === 'patient') {
        filterData.startDate = startDate
          ? date.formatFilterStartDate(startDate)
          : date.formatFilterStartDate(
              date.convertToDayOfYear(sub(new Date(), { days: 30 }))
            );
        filterData.endDate = endDate
          ? date.formatFilterEndDate(endDate)
          : date.formatFilterEndDate(date.convertToDayOfYear(new Date()));
        filterData.category = category;
        filterData.subCategory = subCategory;
        filterData.createdBy = createdBy;
        filterData.patientName = patient;
        filterData.searchEmployeeId = employeeId;
        filterData.status = statuses;
      } else {
        if (selectedDelegate === authorizedUser.facilityId) {
          delete filterData.employeeIds;
          return dispatch(
            getAllFeedbackListByFacilityId({
              location,
              filterData: {
                ...filterData,
                facilityId: authorizedUser.facilityId,
                startDate: startDate
                  ? date.formatFilterStartDate(startDate)
                  : date.formatFilterStartDate(
                      date.convertToDayOfYear(sub(new Date(), { days: 30 }))
                    ),
                endDate: endDate
                  ? date.formatFilterEndDate(endDate)
                  : date.formatFilterEndDate(
                      date.convertToDayOfYear(new Date())
                    ),
                category,
                subCategory,
                createdBy,
                searchEmployeeId: employeeId,
                status: statuses
              }
            })
          );
        }
        filterData = {
          ...filterData,
          startDate: startDate
            ? date.formatFilterStartDate(startDate)
            : date.formatFilterStartDate(
                date.convertToDayOfYear(sub(new Date(), { days: 30 }))
              ),
          endDate: endDate
            ? date.formatFilterEndDate(endDate)
            : date.formatFilterEndDate(date.convertToDayOfYear(new Date())),
          category,
          subCategory,
          createdBy,
          searchEmployeeId: employeeId,
          status: statuses,
          userId: selectedDelegate || authorizedUser.hcaid
        };
      }
      return dispatch(
        getAllFacilityFeedback({
          location,
          filterData
        })
      );
    }
    if (location === 'patient') {
      const selectedUnitsIds = selectedUnits.map(val => val.id);
      return dispatch(
        getAllFacilityFeedback({
          location,
          filterData: {
            ...filterData,
            units: selectedUnitsIds.join(',')
          }
        })
      );
    }
    // For feedback list based on facility
    const filterObj = {
      ...filterData,
      facilityId: selectedDelegate
    };
    if (selectedDelegate === authorizedUser.facilityId) {
      delete filterObj.employeeIds;
      return dispatch(
        getAllFeedbackListByFacilityId({
          location,
          filterData: filterObj
        })
      );
    }
    return dispatch(
      getAllFacilityFeedback({
        location,
        filterData: {
          ...filterData,
          userId: selectedDelegate || authorizedUser.hcaid,
          employeeIds: employeesToRound.map((item: Employee) => item.hcaid)
        }
      })
    );
  }, [
    authorizedUser,
    location,
    selectedDelegate,
    selectedUnitIds,
    feedbackFilter,
    sortDirTask,
    sortKeyTask,
    taskSearchQuery
  ]);

  const downloadAllStoplights = useCallback(() => {
    if (Object.keys(stoplightFilter).length === 0) {
      if (location === 'patient') {
        dispatch(
          getAllStoplightByLocation({
            facilityId: authorizedUser.facilityId,
            locationIds: stoplightSearchQuery
              ? units.map(item => item.unitId)
              : selectedUnits.map(item => item.id),
            locationType: 'unit',
            origin: 'patient',
            sortBy: sortKeyStoplight,
            sortOrder: sortDirStoplight,
            query: stoplightSearchQuery || '',
            startDate: date.formatFilterStartDate(
              date.convertToDayOfYear(sub(new Date(), { days: 30 }))
            )
          })
        );
      } else {
        dispatch(
          getAllStoplightByLocation({
            locationIds: [authorizedUser.facilityId],
            locationType: 'facility',
            origin: 'employee',
            sortBy: sortKeyStoplight,
            sortOrder: sortDirStoplight,
            query: stoplightSearchQuery || '',
            startDate: date.formatFilterStartDate(
              date.convertToDayOfYear(sub(new Date(), { days: 30 }))
            )
          })
        );
      }
    } else {
      const {
        startDate,
        endDate,
        category,
        subCategory,
        createdBy,
        complete,
        opened,
        deferred,
        hasAttachedIssues,
        hasUpdate
      } = stoplightFilter;
      let statuses = [];
      if (complete) {
        statuses.push('completed');
      }
      if (opened) {
        statuses.push('inProgress');
      }
      if (deferred) {
        statuses.push('deferred');
      }
      if (!complete && !opened && !deferred) {
        statuses = ['completed', 'deferred', 'inProgress'];
      }

      if (location === 'patient') {
        dispatch(
          getAllStoplightByLocation({
            locationIds: selectedUnits.map(item => item.id),
            facilityId: authorizedUser.facilityId,
            locationType: 'unit',
            origin: 'patient',
            startDate: startDate
              ? date.formatFilterStartDate(startDate)
              : date.formatFilterStartDate(
                  date.convertToDayOfYear(sub(new Date(), { days: 30 }))
                ),
            endDate: endDate
              ? date.formatFilterEndDate(endDate)
              : date.formatFilterEndDate(date.convertToDayOfYear(new Date())),
            createdBy,
            category,
            subCategory,
            hasIssues: hasAttachedIssues,
            hasComment: hasUpdate,
            status: statuses,
            sortBy: sortKeyStoplight,
            sortOrder: sortDirStoplight,
            query: stoplightSearchQuery || ''
          })
        );
      } else {
        dispatch(
          getAllStoplightByLocation({
            locationIds: [authorizedUser.facilityId],
            locationType: 'facility',
            origin: 'employee',
            startDate: startDate
              ? date.formatFilterStartDate(startDate)
              : date.formatFilterStartDate(
                  date.convertToDayOfYear(sub(new Date(), { days: 30 }))
                ),
            endDate: endDate
              ? date.formatFilterEndDate(endDate)
              : date.formatFilterEndDate(date.convertToDayOfYear(new Date())),
            createdBy,
            category,
            subCategory,
            hasIssues: hasAttachedIssues,
            hasComment: hasUpdate,
            status: statuses,
            sortBy: sortKeyStoplight,
            sortOrder: sortDirStoplight,
            query: stoplightSearchQuery || ''
          })
        );
      }
    }
  }, [
    authorizedUser,
    location,
    sortKeyStoplight,
    sortDirStoplight,
    stoplightFilter,
    authorizedUser,
    stoplightSearchQuery,
    selectedUnits
  ]);

  const handleDownloadClick = useCallback(() => {
    if (view === 'issues' || view === 'feedback' || view === 'stoplight') {
      logTrackingEvent(logTaskClick(view, 'download'));
    }
    return view === 'stoplight'
      ? downloadAllStoplights()
      : view === 'issues'
      ? downloadAllIssues()
      : view === 'feedback'
      ? downloadAllFeedback()
      : downloadStoplight && downloadStoplight();
  }, [
    view,
    downloadAllFeedback,
    downloadAllIssues,
    downloadAllStoplights,
    downloadStoplight,
    logTrackingEvent,
    logTaskClick
  ]);

  // clear the data on first render
  useEffect(() => {
    store.dispatch(clearCsvToExport());
  }, []);

  useEffect(() => {
    // to make this useEffect not to run on first render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (dataToCsvExport?.length) {
      let headersList: { label: string; key: string }[] | string[] =
        view === 'stoplight' || view === 'issues' || view === 'feedback'
          ? []
          : Object.keys(dataToCsvExport[0]);
      if (view === 'stoplight') {
        headersList = [
          { label: 'Created Date', key: 'createdDate' },
          { label: 'Stoplight Id', key: 'friendlyId' },
          { label: 'Created By', key: 'createdBy' },
          { label: 'Facility', key: 'facility' },
          {
            label: location === 'patient' ? 'Unit' : 'Department',
            key: 'location'
          },
          { label: 'Category', key: 'category' },
          { label: 'Subcategory', key: 'subCategory' },
          { label: 'Description', key: 'details' },
          { label: 'Attached Issues', key: 'attachedIssues' },
          { label: 'Last Comment', key: 'latestComment' },
          { label: 'Status', key: 'status' }
        ];
      } else if (view === 'issues') {
        // when view is issues
        headersList = [
          { label: 'Created Date', key: 'taskDate' },
          { label: 'Issue Id', key: 'friendlyId' },
          { label: 'Created by', key: 'userFullName' },
          { label: 'Facility', key: 'facility' },
          {
            label: location === 'patient' ? 'Unit' : 'Department',
            key: 'location'
          },
          { label: 'Category', key: 'category' },
          { label: 'Subcategory', key: 'subCategory' },
          { label: 'Description', key: 'description' },
          {
            label: location === 'patient' ? 'Patient' : 'Employee',
            key: 'name'
          },
          { label: 'Last Comment', key: 'latestComment' },
          { label: 'Attached To', key: 'attachedTo' },
          { label: 'Status', key: 'status' }
        ];
      } else if (view === 'feedback') {
        // when view is feedback
        headersList = [
          { label: 'Created Date', key: 'taskDate' },
          { label: 'Created by', key: 'userFullName' },
          { label: 'Facility', key: 'facility' },
          {
            label: location === 'patient' ? 'Unit' : 'Department',
            key: 'location'
          },
          { label: 'Type', key: 'taskSubtype' },
          { label: 'Category', key: 'category' },
          { label: 'Subcategory', key: 'subCategory' },
          { label: 'Description', key: 'description' },
          {
            label: 'Employee Name',
            key: 'employeeName'
          },
          { label: 'Status', key: 'status' }
        ];
        if (location === 'patient') {
          headersList.splice(9, 0, {
            label: 'Patient Name',
            key: 'patientName'
          });
        }
      }
      setCsvHeaders(headersList);
      setCsvData(dataToCsvExport);

      // get current date to make it to the file name
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = (today.getMonth() + 1).toString();
      let dd = today.getDate().toString();
      if (dd.length < 2) dd = `0${dd}`;
      if (mm.length < 2) mm = `0${mm}`;
      const fileName = `${fileNamePrefix}-${mm}-${dd}-${yyyy}`;
      setCsvFileName(fileName);
    }
  }, [dataToCsvExport]);

  useEffect(() => {
    if (csvData?.length && csvHeaders?.length)
      csvLinkRef?.current?.link.click();
  }, [csvData]);

  return (
    <div>
      <NeuButton
        id="Download-Button"
        className="w-100 mr-2"
        color="primary"
        fill="outline"
        onClick={handleDownloadClick}
        disabled={isLoading}
      >
        {isLoading ? (
          <NeuSpinner
            style={{ marginRight: '10px' }}
            ariaLabel="default"
            width="18"
            height="18"
          />
        ) : (
          <NeuIcon className="mr-2" feedback="default">
            get_app
          </NeuIcon>
        )}
        Download
      </NeuButton>
      <CSVLink
        ref={csvLinkRef}
        data={csvData}
        headers={csvHeaders}
        filename={csvFileName}
        style={{ display: 'none' }}
      >
        DOWNLOAD
      </CSVLink>
    </div>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser?.authorizedUser,
    dataToCsvExport: state.AdminReducer.dataToCsvExport,
    employeesToRound: state.EmployeeReducer.employeesToRound,
    isLoading: state.AdminReducer.isLoadingCsvExport,
    issueFilter: state.TaskReducer.issueFilter,
    selectedDelegate: state.EmployeeReducer.selectedDelegatedEmployee.hcaid,
    stoplightFilter: state.StoplightReducer.stoplightFilter,
    sortDirTask: state.TaskReducer.sortDir,
    sortKeyTask: state.TaskReducer.sortKey,
    sortDirStoplight: state.StoplightReducer.sortDir,
    sortKeyStoplight: state.StoplightReducer.sortKey,
    location: state.UserReducer.userSection,
    selectedUnits: state.ConfigReducer.selectedUnits,
    feedbackFilter: state.TaskReducer.feedbackFilter,
    stoplightSearchQuery: state.StoplightReducer.stoplightSearchQuery,
    taskSearchQuery: state.TaskReducer.taskSearchQuery,
    units: state.ConfigReducer.units
  };
};

export default connect(mapReduxStateToProps)(DownloadBtn);
