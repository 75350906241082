/**
 * External Imports
 */
import { FC, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { NeuIcon, NeuButton, NeuCardTitle, NeuLabel } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';
/**
 * Internal Imports
 */
import EmployeeSearch from '../reusableModalPieces/employeeSearch';
import {
  getEmployeeNotes,
  setSelectedEmployee
} from '../../../redux/actions/User.action';

import store, { RootState } from '../../../redux/store';
import { clearEmployeeRoundHistory } from '../../../redux/actions/Report.action';
import { clearTasksByEmployeeId } from '../../../redux/actions/Task.action';

import { logRoundProfile } from '../../../utils/analyticsHelpers';
import { handleUnlistedModal } from '../../../services/ModalPortal/utils';
/**
 * Global Type Definition Imports
 */
import { Employee } from '../../../config/interfaces';
/**
 * Style Imports
 */
import {
  CardHeader,
  ModalCardContent,
  UnlistedPatientRoundCard
} from '../unlistedPatientRoundModal/UnlistedPatientRoundModal.styles';

const UnlistedEmployeeRoundModal: FC = () => {
  const [employeeObj, setEmployeeObj] = useState<Employee>({} as Employee);
  const [showButton, setShowButton] = useState<boolean>(false);
  const navigate = useNavigate();
  const modalContentRef = useRef<HTMLNeuCardElement>(null);

  const { logTrackingEvent } = useAnalyticsApi();

  const { pathname } = useLocation();
  const page = pathname.split('/')[1];

  const selectEmployee = (emp: Employee) => {
    setEmployeeObj(emp);
    setShowButton(true);
  };

  const handlePost = () => {
    if (showButton) {
      store.dispatch(clearEmployeeRoundHistory());
      store.dispatch(clearTasksByEmployeeId());
      store.dispatch(setSelectedEmployee(employeeObj));
      store.dispatch(getEmployeeNotes(employeeObj?.hcaid));
      logTrackingEvent(logRoundProfile());
      navigate(
        page === 'employees'
          ? '/employees/profile'
          : page === 'support'
          ? pathname.includes('csc-list')
            ? '/support/profile/csc'
            : '/support/profile/cned'
          : '/'
      );
      handleUnlistedModal(modalContentRef);
    }
  };

  const handleRemoveChip = () => {
    setEmployeeObj({} as Employee);
    setShowButton(false);
  };

  return (
    <UnlistedPatientRoundCard
      className="show-modal"
      id="Unlisted-Employee-Modal"
      type="submit"
      maxWidth="413px"
      minHeight="100vh"
      ref={modalContentRef}
    >
      <CardHeader>
        <NeuCardTitle>
          <NeuIcon large color="primary-40" style={{ alignItems: 'center' }}>
            supervisor_account
          </NeuIcon>
          <NeuLabel style={{ color: '#093865', marginLeft: 10 }}>
            Search All Employees
          </NeuLabel>
        </NeuCardTitle>
        <NeuButton
          fill="flat"
          onClick={() => handleUnlistedModal(modalContentRef)}
        >
          <NeuIcon large color="primary">
            close
          </NeuIcon>
          Close
        </NeuButton>
      </CardHeader>
      <ModalCardContent>
        <EmployeeSearch
          val={employeeObj}
          chipVal={
            employeeObj && Object.keys(employeeObj).length > 0
              ? `${employeeObj?.firstName} ${employeeObj?.lastName}`
              : undefined
          }
          setEmployeeSelection={(emp: Employee) => selectEmployee(emp)}
          handleRemoveEmployee={handleRemoveChip}
          showLabel={false}
        />
      </ModalCardContent>
      <NeuButton
        id="Unlisted-Employee-Begin-Round-Button"
        color="primary"
        style={{ marginRight: 20, marginBottom: 26, marginLeft: 263 }}
        onClick={handlePost}
        disabled={!showButton ? true : undefined}
      >
        Start Round
      </NeuButton>
    </UnlistedPatientRoundCard>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    modalState: state.ModalReducer.modal
  };
};

export default connect(mapReduxStateToProps)(UnlistedEmployeeRoundModal);
