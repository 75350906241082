import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { differenceInYears } from 'date-fns';

import { NeuIcon } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import {
  HeaderTitle,
  PatientInfo,
  PatientProfileContainer,
  SubToolbar
} from './SubNavPatientProfile.styles';
import { AppDispatch, RootState } from '../../../redux/store';
import { clearRoundState } from '../../../redux/actions/Round.action';
import { logTableStart } from '../../../utils/analyticsHelpers';
import { calculateStayTime } from '../../../utils/helpers';

import { Patient } from '../../../config/interfaces';

interface ISubNavPatientProfileProps {
  selectedPatient?: Patient;
  clearRoundingState: () => void;
  unListedPatientParams: Patient;
}

const SubNavPatientProfile: FC<ISubNavPatientProfileProps> = ({
  selectedPatient,
  clearRoundingState,
  unListedPatientParams
}) => {
  const navigate = useNavigate();
  const [age, setAge] = useState<number | string>();
  // TODO: Should we handle unlisted patients in the future?
  const [dataToDisplay, setDataToDisplay] = useState<Patient>();

  const { logTrackingEvent } = useAnalyticsApi();

  useEffect(() => {
    const today = new Date();
    if (selectedPatient) {
      const birthDate = new Date(
        parseInt(selectedPatient.dateOfBirth?.substring(0, 4), 10),
        parseInt(selectedPatient.dateOfBirth?.substring(4, 6), 10) - 1,
        parseInt(selectedPatient.dateOfBirth?.substring(6, 8), 10)
      );
      setAge(differenceInYears(today, birthDate));
      setDataToDisplay(selectedPatient);
    } else {
      setDataToDisplay(unListedPatientParams);
      setAge('NA');
    }
  }, [selectedPatient, unListedPatientParams]);

  const goback = () => {
    clearRoundingState();
    navigate('/patients/census');
    logTrackingEvent(logTableStart('/patients/census'));
  };

  return (
    <SubToolbar id="SubNav-Patient">
      <span
        onClick={goback}
        onKeyDown={goback}
        role="button"
        tabIndex={0}
        style={{
          marginTop: 10,
          marginLeft: 10,
          position: 'absolute',
          display: 'flex',
          flex: '1',
          flexDirection: 'row',
          cursor: 'pointer'
        }}
      >
        <NeuIcon feedback="default" color="primary" left large>
          chevron_left
        </NeuIcon>

        <span
          style={{
            color: '#2075AD',
            fontSize: 14,
            marginTop: 4
          }}
        >
          Patient Census
        </span>
      </span>
      <PatientProfileContainer>
        <HeaderTitle>
          Name
          <PatientInfo>
            {`
${dataToDisplay?.firstName} ${dataToDisplay?.lastName}`}
          </PatientInfo>
        </HeaderTitle>
        <HeaderTitle>
          MRN
          <PatientInfo>
            {`
${dataToDisplay?.mrn}`}
          </PatientInfo>
        </HeaderTitle>
        <HeaderTitle>
          Age|Sex
          <PatientInfo>
            {age && selectedPatient?.sex
              ? `
${age} | ${selectedPatient?.sex}`
              : `
N/A`}
          </PatientInfo>
        </HeaderTitle>
        <HeaderTitle>
          Length of Stay
          <PatientInfo>
            {selectedPatient?.lengthOfStay !== -1
              ? `\n${calculateStayTime(selectedPatient?.lengthOfStay || 0)}`
              : `\nN/A`}
          </PatientInfo>
        </HeaderTitle>
        <HeaderTitle>
          Room
          <PatientInfo>
            {selectedPatient?.room
              ? `
${selectedPatient?.room}`
              : `
N/A`}
          </PatientInfo>
        </HeaderTitle>
        <HeaderTitle>
          Unit
          <PatientInfo>
            {selectedPatient?.unitId
              ? `
${selectedPatient?.unitId}`
              : `
N/A`}
          </PatientInfo>
        </HeaderTitle>
        <HeaderTitle>
          Attending Physician
          <PatientInfo>
            {selectedPatient?.attendingPhysician
              ? `
${selectedPatient?.attendingPhysician}`
              : `
N/A`}
          </PatientInfo>
        </HeaderTitle>
        <HeaderTitle>
          Chief Complaint
          <PatientInfo>
            {selectedPatient?.chiefComplaint
              ? `
${selectedPatient?.chiefComplaint}`
              : `
N/A`}
          </PatientInfo>
        </HeaderTitle>
      </PatientProfileContainer>
    </SubToolbar>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  clearRoundingState: () => dispatch(clearRoundState([]))
});
const mapReduxStateToProps = (state: RootState) => ({
  selectedPatient: state.UserReducer.selectedPatient,
  unListedPatientParams: state.UserReducer.unlistedPatientRoundParams
});

export default connect(
  mapReduxStateToProps,
  mapDispatchToProps
)(SubNavPatientProfile);
