/**
 * External Imports
 */
import { FC, useEffect, Dispatch, SetStateAction } from 'react';
import { connect } from 'react-redux';
/**
 * Internal Imports
 */
import SearchHeader from '../../components/searchHeader';
import { CensusContainer } from '../patientViews/styles/PatientViews.styles';
import DischargesTable from '../../components/customTables/dischargesTable/DischargesTable';
import Loading from '../../components/shared/Loading';

import store, { RootState } from '../../redux/store';
import { getPatientsToRound } from '../../redux/actions/User.action';
/**
 * Global Type Definition Imports
 */
import { Patient } from '../../config/interfaces';

interface DischargeProps {
  patientsToRound: Patient[];
  searchTerm: string;
  selectedFacility: string;
  isLoading: boolean;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const AdminDischarge: FC<DischargeProps> = ({
  patientsToRound = [],
  searchTerm,
  selectedFacility,
  isLoading = false,
  setSearchTerm
}) => {
  useEffect(() => {
    if (selectedFacility) {
      store.dispatch(getPatientsToRound({ facilityId: selectedFacility }));
    }
  }, [selectedFacility]);

  return (
    <CensusContainer id="Admin-Discharge">
      <div style={{ width: '100%' }}>
        <SearchHeader searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        {isLoading ? (
          <Loading centered={false} />
        ) : (
          <DischargesTable
            searchTerm={searchTerm}
            patientsToRound={patientsToRound}
            facilityId={JSON.stringify(selectedFacility)}
          />
        )}
      </div>
    </CensusContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    patientsToRound: state.UserReducer.patientsToRound,
    selectedFacility: state.AdminReducer.selectedFacility,
    isLoading: state.UserReducer.isLoadingGetPatientsToRound
  };
};

export default connect(mapReduxStateToProps)(AdminDischarge);
