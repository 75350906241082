import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { useNavigate, useParams } from 'react-router-dom';

import HistorySideNav from '../../components/historySideNav/HistorySideNav';
import HistorySideDrawer from '../../components/historySideDrawer';
import Round from '../../components/round/Round';

import store, { RootState } from '../../redux/store';
import {
  clearEmployeeSearch,
  searchEmployees
} from '../../redux/actions/Employee.action';
import { getEmployeeRoundHistory } from '../../redux/actions/Report.action';
import { getTemplateMaster } from '../../redux/actions/Template.action';
import { clearSelectedEmployee } from '../../redux/actions/User.action';

import { Employee, RoundHistory } from '../../config/interfaces';

interface SupportProfileProps {
  selectedEmployee?: Employee;
  employeeRoundHistory?: RoundHistory;
}

const SupportProfile: FC<SupportProfileProps> = ({
  selectedEmployee,
  employeeRoundHistory
}) => {
  const [selectedTab, setSelectedTab] = useState<string>('');

  const navigate = useNavigate();
  const { supportType } = useParams();

  useEffect(() => {
    return () => {
      store.dispatch(clearEmployeeSearch());
      store.dispatch(clearSelectedEmployee());
    };
  }, []);

  useEffect(() => {
    if (selectedEmployee) {
      store.dispatch(
        getEmployeeRoundHistory({
          employeeId: selectedEmployee.hcaid,
          type: 'support',
          supportType: supportType === 'csc' ? 'csrn' : supportType
        })
      );
      store.dispatch(getTemplateMaster({ condensed: true, archived: false }));
      store.dispatch(searchEmployees(selectedEmployee.hcaid));
    } else {
      navigate(window.localStorage.getItem('orbitLastPath') ?? '/');
    }
  }, []);

  return (
    <div
      id="Support-Profile"
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: 40,
        flex: 1,
        backgroundColor: 'white',
        height: '100%'
      }}
    >
      <HistorySideNav
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        type="support"
        supportType={supportType}
      />
      <HistorySideDrawer
        selectedTab={selectedTab}
        selectedPerson={selectedEmployee}
        roundHistory={employeeRoundHistory}
        tasks={[]}
        type="support"
        supportType={supportType === 'csc' ? 'csrn' : supportType}
        setSelectedTab={setSelectedTab}
      />
      <Round
        type="support"
        supportType={supportType === 'csc' ? 'csrn' : supportType}
      />
    </div>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    selectedEmployee: state.UserReducer.selectedEmployee,
    employeeRoundHistory: state.ReportReducer.employeeRoundHistory
  };
};

export default connect(mapReduxStateToProps)(SupportProfile);
