/**
 * External Imports
 */
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
/**
 * Internal Imports
 */
import CSRNList from './supportLists/CSRNList';
import CNEdList from './supportLists/CNEdList';
import SupportProfile from './SupportProfile';
import { clearAllDepts } from '../../redux/actions/Employee.action';
import {
  resetUserSectionType,
  setUserSectionType
} from '../../redux/actions/User.action';
import { useFeatures } from '../../services/Features/features';
import { useUserRolesRef } from '../../services/UserRoles/userRoles';
import FeatureRoute from '../featureRoute/FeatureRoute';

const Support: FC<{}> = () => {
  const dispatch = useDispatch();

  const { isCSRNEnabled, isCNEdEnabled } = useFeatures();
  const { isCSRNRounder, isCNEdRounder } = useUserRolesRef();

  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    dispatch(setUserSectionType('support'));
    return () => {
      dispatch(resetUserSectionType());
      dispatch(clearAllDepts());
    };
  }, []);

  return (
    <div>
      <Routes>
        <Route
          index
          element={<Navigate to={isCSRNRounder ? 'csc-list' : 'cned-list'} />}
        />
        {isCSRNRounder && (
          <Route
            path="csc-list"
            element={
              <FeatureRoute
                component={
                  <CSRNList
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                  />
                }
                isFeatureEnabled={isCSRNEnabled}
                offsetHeight={72}
              />
            }
          >
            <Route
              path=":csrnFilter"
              element={
                <CSRNList
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
              }
            />
          </Route>
        )}
        {isCNEdRounder && (
          <Route
            path="cned-list"
            element={
              <FeatureRoute
                component={
                  <CNEdList
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                  />
                }
                isFeatureEnabled={isCNEdEnabled}
                offsetHeight={72}
              />
            }
          >
            <Route
              path=":cnedFilter"
              element={
                <CNEdList
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
              }
            />
          </Route>
        )}
        <Route path="profile" element={<Outlet />}>
          <Route path=":supportType" element={<SupportProfile />} />
        </Route>
        <Route
          path="*"
          element={
            <Navigate to={localStorage.getItem('orbitLastPath') || '/'} />
          }
        />
      </Routes>
    </div>
  );
};

export default Support;
