import { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';

import ReusableTabs from '../../components/reusableTabs';
import Login from '../login/Login';
import PatientCensus from './PatientCensus';
import PatientIssues from './PatientIssues';
import PatientFeedback from './PatientFeedback';
import PatientStoplight from './PatientStoplight';
import PatientProfile from './PatientProfile';

import store, { AppDispatch, RootState } from '../../redux/store';
import { setStoplightSearchQuery } from '../../redux/actions/Stoplight.action';
import { setTaskSearchQuery } from '../../redux/actions/Task.action';
import {
  setUserSectionType,
  resetUserSectionType
} from '../../redux/actions/User.action';
import { useDebounceValue } from '../../utils/debouncers';

const Patient: FC<{
  // selectedPatient: IPatient;
  setStoplightStoreSearch: (data: string) => void;
  setTaskStoreSearch: (data: string) => void;
}> = ({ setStoplightStoreSearch, setTaskStoreSearch }) => {
  // let match = useMatch();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const dbSearchTerm = useDebounceValue(searchTerm, 250);

  const { '*': view } = useParams();
  const { pathname } = useLocation();

  /**
   * In order to identify whether the user is in Employee or Patient section.
   * store variable: userSection
   */
  useEffect(() => {
    store.dispatch(setUserSectionType('patient'));
    return () => {
      store.dispatch(resetUserSectionType());
    };
  }, []);

  useEffect(() => {
    if (view === 'issues') setTaskStoreSearch(searchTerm);
    if (view === 'feedback') setTaskStoreSearch(searchTerm);
    if (view === 'stoplight') setStoplightStoreSearch(searchTerm);
    return () => {
      setTaskStoreSearch('');
      setStoplightStoreSearch('');
    };
  }, [dbSearchTerm]);

  const reusableTabItems = {
    base: '/patients',
    links: [
      { label: 'Census', path: '/census' },
      { label: 'Issues', path: '/issues' },
      { label: 'Feedback', path: '/feedback' },
      { label: 'Stoplight', path: '/stoplight' }
    ]
  };

  return (
    <div>
      {!pathname.includes('/profile') && (
        <ReusableTabs tabItems={reusableTabItems} />
      )}
      <Routes>
        {/* <Route path="*" element={<Navigate to="census" />} /> */}
        <Route
          path="census"
          element={
            <PatientCensus
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route
          path="issues"
          element={
            <PatientIssues
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route
          path="feedback"
          element={
            <PatientFeedback
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route
          path="stoplight"
          element={
            <PatientStoplight
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route path="profile" element={<PatientProfile />} />
        <Route path="*" element={<Login />} />
      </Routes>
    </div>
  );
};
const mapReduxStateToProps = (state: RootState) => ({
  selectedPatient: state.UserReducer.selectedPatient
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setStoplightStoreSearch: (data: string) =>
    dispatch(setStoplightSearchQuery(data)),
  setTaskStoreSearch: (data: string) => dispatch(setTaskSearchQuery(data))
});

export default connect(mapReduxStateToProps, mapDispatchToProps)(Patient);
