import {
  FC,
  useEffect,
  // useRef,
  useState
} from 'react';
import { connect } from 'react-redux';
import { format, isMatch, parse } from 'date-fns';

import { NeuSpinner } from '@neutron/react';

import { handleBlobPicUrl, userProfilePicUrl } from '../../../utils/helpers';

import { Avatar } from '../mainNav/MainNav.styles';

import { AppDispatch, RootState } from '../../../redux/store';
import { updateUserPic } from '../../../redux/actions/User.action';

// import { toast } from '../../../services/Toast';
import {
  HeaderTitle,
  UserInfo,
  AccountHeader,
  AccountInfoContainer,
  // EditButton,
  // EditContainer,
  SubToolbar
} from './SubNavAccount.styles';
import { EmployeeAvatar } from '../../customTables/employeesTable/EmployeesTable.styles';
import { AuthUserProps, Employee } from '../../../config/interfaces';
import defaultImage from '../../../assets/images/defaultImage.png';

interface ISubNavAccountProps {
  userImageUrl?: string;
  authorizedUser?: AuthUserProps;
  sasToken: string;
  employeeDetails?: Employee;
  isLoading: boolean;
  // uploadNewPic: (file: File) => void;
}

const SubNavAccount: FC<ISubNavAccountProps> = ({
  userImageUrl = '',
  authorizedUser,
  sasToken,
  employeeDetails,
  isLoading
  // uploadNewPic
}) => {
  const [anniversary, setAnniversary] = useState<string>('');
  const [effectiveStartDate, setEffectiveStartDate] = useState<string>('');
  // const photoFileInputEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (employeeDetails) {
      if (
        employeeDetails.effectiveStartDate &&
        employeeDetails.effectiveStartDate !== 'NULL'
      ) {
        setEffectiveStartDate(
          !isMatch(employeeDetails.effectiveStartDate, 'MM/dd/yyyy')
            ? format(
                parse(
                  employeeDetails.effectiveStartDate,
                  'yyyyMMdd',
                  new Date()
                ),
                'MM/dd/yyyy'
              )
            : employeeDetails.effectiveStartDate
        );
      } else if (employeeDetails.startDate) {
        setEffectiveStartDate(employeeDetails.startDate);
      } else setEffectiveStartDate('N/A');

      if (employeeDetails.annivDate) {
        setAnniversary(employeeDetails.annivDate);
      } else {
        setAnniversary('N/A');
      }
    }
  }, [employeeDetails]);

  // const handleFileUpload = async (e: React.BaseSyntheticEvent<MouseEvent>) => {
  //   const ext = e.target.files[0].name.match(/\.([^.]+)$/)[1].toLowerCase();
  //   switch (ext) {
  //     case 'jpg':
  //     case 'bmp':
  //     case 'png':
  //     case 'tif':
  //     case 'gif':
  //     case 'tiff':
  //     case 'jpeg':
  //     case 'raw':
  //       uploadNewPic(e.target.files[0]);
  //       // appInsights.trackEvent({
  //       //   name: 'Picture_Upload_Successful'
  //       // });
  //       break;
  //     default:
  //       toast('This format is not allowed!', 'error', null, 500, true);
  //     // appInsights.trackEvent({
  //     //   name: 'Picture_Upload_Failed'
  //     // });
  //   }
  // };
  // const handleFileSubmit = (e: React.BaseSyntheticEvent<MouseEvent>) => {
  //   e.preventDefault();
  //   handleFileUpload(e);
  // };

  return (
    <SubToolbar id="SubNav-Account">
      <AccountHeader>MY ACCOUNT</AccountHeader>
      <AccountInfoContainer>
        <HeaderTitle className="d-flex flex-row align-items-center">
          <Avatar
            id="SubNav-Account-User-Image"
            slot="avatar"
            color="gray-60"
            style={{ height: '40px', width: '40px' }}
          >
            {!isLoading ? (
              sasToken && userImageUrl ? (
                <EmployeeAvatar
                  src={
                    handleBlobPicUrl(userImageUrl, sasToken) ??
                    userProfilePicUrl(
                      authorizedUser?.hcaid || 'default',
                      sasToken
                    )
                  }
                  alt="User"
                  onError={e => {
                    e.currentTarget.src = defaultImage;
                    return null;
                  }}
                />
              ) : (
                <EmployeeAvatar src={defaultImage} alt="Default Image" />
              )
            ) : (
              <NeuSpinner ariaLabel="spinner" color="plain-0" tiny />
            )}
          </Avatar>
          <HeaderTitle id="SubNav-Account-Name-34">
            Name & 3-4 ID
            <UserInfo>
              {`${authorizedUser?.firstName ? authorizedUser.firstName : ''} ${
                authorizedUser?.lastName ? authorizedUser.lastName : ''
              } | ${authorizedUser?.hcaid ? authorizedUser.hcaid : ''}`}
            </UserInfo>
          </HeaderTitle>
        </HeaderTitle>
        <HeaderTitle id="SubNav-Account-Email">
          Email
          <UserInfo>
            {`${authorizedUser?.email ? authorizedUser.email : ''}`}
          </UserInfo>
        </HeaderTitle>
        <HeaderTitle id="SubNav-Account-Dep-Start">
          Dept Start Date
          <UserInfo>{`${effectiveStartDate}`}</UserInfo>
        </HeaderTitle>
        <HeaderTitle id="SubNav-Account-Anniversary">
          Anniversary
          <UserInfo>{`${anniversary}`}</UserInfo>
        </HeaderTitle>
      </AccountInfoContainer>
      {/* TODO: Possibly put back in later once GHR pics are ingested */}
      {/* <EditContainer>
        <input
          type="file"
          onChange={(e: React.BaseSyntheticEvent<MouseEvent>) => {
            handleFileSubmit(e);
          }}
          ref={photoFileInputEl}
          hidden
        />
        <EditButton
          type="button"
          onClick={() => photoFileInputEl.current!.click()}
        >
          Edit
        </EditButton>
      </EditContainer> */}
    </SubToolbar>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  uploadNewPic: (file: File) => dispatch(updateUserPic(file))
});

const mapReduxStateToProps = (state: RootState) => ({
  authorizedUser: state.AuthorizedUser.authorizedUser,
  userImageUrl: state.AuthorizedUser.authorizedUser.pictureUrl,
  sasToken: state.ConfigReducer.sasToken,
  employeeDetails: state.EmployeeReducer.employeeAccountDetails[0],
  isLoading: state.UserReducer.isLoading
});

export default connect(mapReduxStateToProps, mapDispatchToProps)(SubNavAccount);
