import { FC, Dispatch, SetStateAction, useState } from 'react';
import { connect } from 'react-redux';
import {
  format,
  isFirstDayOfMonth,
  previousSaturday,
  sub,
  startOfQuarter
} from 'date-fns';
import {
  NeuHint,
  NeuIcon,
  NeuLabel,
  NeuOption,
  NeuTooltip
} from '@neutron/react';

import ExportCsvBtn from '../exportCsvBtn';
import DateSelectsSection from '../../modals/reusableModalPieces/dateSelectsSection';
import { Dropdown } from '../../shared/dropdowns';

import { RootState } from '../../../redux/store';

import { unCamelCase } from '../../../utils/helpers';

import { DatesStartEnd } from '../../../config/interfaces';

import {
  CompHeaderContainer,
  DateRangeSection,
  HintSection,
  Range,
  StartEnd
} from './ComplianceHeader.styles';
import { InputHeading } from '../../modals/reusableModalPieces/dateSelectsSection/DateSelectsSection.styles';

interface IComplianceHeaderProps {
  customDates: DatesStartEnd;
  setCustomDates: Dispatch<SetStateAction<DatesStartEnd>>;
  handleExportCsv?: () => void;
}

const dateRanges = [
  { value: 'custom', displayName: 'Custom' },
  { value: 'monthToDate', displayName: 'Month to date' },
  { value: 'lastFullMonth', displayName: 'Last full month' },
  { value: 'lastFullQuarter', displayName: 'Last full quarter' }
];

const ComplianceHeader: FC<IComplianceHeaderProps> = ({
  customDates,
  handleExportCsv,
  setCustomDates
}) => {
  const [reportDateRangeType, setReportDateRangeType] =
    useState<string>('Month To Date');
  const [showCustomDate, setShowCustomDate] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const handleSelects = (e: any) => {
    const { name } = e.target;
    const { value } = e.detail;
    setCustomDates({
      ...customDates,
      [name]: value
    });
  };

  const handleDateRangeSelect = (ev: any) => {
    const selectedRange = ev.target.value;
    setReportDateRangeType(unCamelCase(selectedRange));

    const today = new Date();

    if (selectedRange === 'monthToDate') {
      setShowCustomDate(false);

      const firstDayOfMonth = format(
        new Date(today.getFullYear(), today.getMonth(), 1),
        'yyyy-MM-dd'
      );

      const dayYesterday = format(
        isFirstDayOfMonth(today) ? today : sub(today, { days: 1 }),
        'yyyy-MM-dd'
      );

      setCustomDates({
        startDate: firstDayOfMonth,
        endDate: dayYesterday
      });
    }

    if (selectedRange === 'lastFullWeek') {
      setShowCustomDate(false);
      const lastSaturday = previousSaturday(new Date());
      const lastSaturdayFormatted = format(lastSaturday, 'yyyy-MM-dd');

      const secondLastSunday = format(
        new Date(
          lastSaturday.getFullYear(),
          lastSaturday.getMonth(),
          lastSaturday.getDate() - 6
        ),
        'yyyy-MM-dd'
      );

      setCustomDates({
        startDate: secondLastSunday,
        endDate: lastSaturdayFormatted
      });
    }

    if (selectedRange === 'lastFullMonth') {
      setShowCustomDate(false);

      const lastMonthFirstDay = format(
        new Date(today.getFullYear(), today.getMonth() - 1, 1),
        'yyyy-MM-dd'
      );

      const lastMonthLastDay = format(
        sub(new Date(today.getFullYear(), today.getMonth(), 1), { days: 1 }),
        'yyyy-MM-dd'
      );

      setCustomDates({
        startDate: lastMonthFirstDay,
        endDate: lastMonthLastDay
      });
    }

    if (selectedRange === 'lastFullQuarter') {
      setShowCustomDate(false);

      const lastQuarterLastDay = sub(startOfQuarter(today), { days: 1 });
      const lastQuarterLastDayFormatted = format(
        lastQuarterLastDay,
        'yyyy-MM-dd'
      );
      const lastQuarterFirstDay = format(
        startOfQuarter(
          new Date(
            lastQuarterLastDay.getFullYear(),
            lastQuarterLastDay.getMonth(),
            lastQuarterLastDay.getDate() - 1
          )
        ),
        'yyyy-MM-dd'
      );

      setCustomDates({
        startDate: lastQuarterFirstDay,
        endDate: lastQuarterLastDayFormatted
      });
    }

    if (selectedRange === 'custom') {
      setShowCustomDate(true);
    }
  };

  return (
    <CompHeaderContainer>
      <DateRangeSection style={{ marginTop: '-15px' }}>
        <Range>
          <InputHeading className="pt-2">Date Range</InputHeading>
          <Dropdown
            classes="pl-0 pt-0"
            open={open}
            placeholder={reportDateRangeType}
            styles={{ minWidth: '200px' }}
            width="93%"
            setOpen={setOpen}
          >
            {dateRanges.map(range => (
              <NeuOption
                className={`dropdown-hover-item${
                  unCamelCase(range.value) === reportDateRangeType
                    ? ' selected'
                    : ''
                }`}
                key={range.value}
                value={range.value}
                onClick={(e: any) => handleDateRangeSelect(e)}
              >
                {range.displayName}
              </NeuOption>
            ))}
          </Dropdown>
        </Range>
        {showCustomDate && (
          <StartEnd>
            <DateSelectsSection
              filterType="complianceReport"
              handleSelects={handleSelects}
              startDate={customDates.startDate}
              endDate={customDates.endDate}
            />
          </StartEnd>
        )}
        <HintSection>
          <NeuTooltip className="mt-1">
            <NeuIcon small color="primary" slot="content" className="ml-1">
              help
            </NeuIcon>
            <NeuLabel slot="tooltip" background-color="primary-100">
              Compliance is calculated by the total number of rounds completed
              divided by the <br />
              total number of patients in the selected unit(s) for the selected
              date range.
              <br />
              The units and date range selections can be changed using the
              drop-down menus.
            </NeuLabel>
          </NeuTooltip>
          <NeuHint color="primary" className="ml-2 mb-3 mt-2">
            Report Metrics
          </NeuHint>
        </HintSection>
      </DateRangeSection>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ExportCsvBtn
          handleExportCsv={handleExportCsv}
          fileNamePrefix="Compliance Report"
        />
      </div>
    </CompHeaderContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    modalState: state.ModalReducer.modal
  };
};

export default connect(mapReduxStateToProps)(ComplianceHeader);
