/**
 * External Imports
 */
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { NeuDivider, NeuOption } from '@neutron/react';
/**
 * Internal Imports
 */
import { RootState } from '../../../redux/store';
import {
  getFacilityUnits,
  setSelectedUnits,
  getAllUnits,
  setAllPrevChecked,
  setFavsPrevChecked
} from '../../../redux/actions/Config.action';
import { Dropdown } from '../../shared/dropdowns';
import {
  getEmployeesToRound,
  setDelegatedEmployee,
  clearEmployeesToRound,
  setSelectedDepts as setSelectedDeptsInStore
} from '../../../redux/actions/Employee.action';
import { setAdminFacility } from '../../../redux/actions/Admin.action';
import {
  setCurrentFeedbackPage,
  setCurrentIssuePage
} from '../../../redux/actions/Task.action';
import { formatRoundingType, toTitleCaseSpecial } from '../../../utils/helpers';
import DeptMultiSelectDropdown from '../../customDropdowns/deptMultiSelectDropdown';
import { useDebounceValue } from '../../../utils/debouncers';
/**
 * Global Type Definition Imports
 */
import { Unit, IFacility } from '../../../config/interfaces';
/**
 * Style Imports
 */
import {
  SubTitle,
  Title,
  TitleContainer,
  StyledCheckbox,
  StyledCheckmark,
  StyledCheckboxLabel,
  StyledOptionDiv,
  SubToolbar
} from './SubNav.styles';

interface IDelegateOption {
  employeeName: string;
  hcaid: string;
}

interface IUnitOption {
  checked: boolean;
  id: string;
  value: string;
}

interface ISubNavProps {
  depts: string[];
  divisionTitle: string;
  employeesDelegatedToMe: IDelegateOption[];
  isAllPrevChecked: boolean;
  isFavsPrevChecked: boolean;
  facilities: IFacility[];
  facilityId: string;
  facilityTitle: string;
  favUnits: Unit[];
  selectedDelegatedEmployee: IDelegateOption;
  selectedDeptsFromStore: string[];
  selectedUnits: { id: string; unit: string }[];
  units: Unit[];
  userId: string;
  userSection: string;
  visited: boolean;
}

const isFavUnit = (favs: Unit[], id: string) =>
  favs.some((primary: Unit) => primary.unitId === id);

const SubNav: FC<ISubNavProps> = ({
  depts,
  divisionTitle,
  employeesDelegatedToMe,
  facilities,
  facilityId,
  facilityTitle,
  favUnits,
  isFavsPrevChecked,
  isAllPrevChecked,
  selectedDelegatedEmployee,
  selectedDeptsFromStore,
  selectedUnits,
  units,
  userId,
  userSection,
  visited
}) => {
  const dispatch = useDispatch();
  const { '*': view } = useParams();

  const [unitOptions, setUnitOptions] = useState<IUnitOption[]>([]);
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
  const [isFavsChecked, setIsFavsChecked] = useState<boolean>(false);
  const [selectedDelegate, setSelectedDelegate] = useState<IDelegateOption>({
    employeeName: 'My List',
    hcaid: userId
  });
  const [selectedFacility, setSelectedFacility] = useState('');
  const [delegateListOptions, setDelegateListOptions] = useState<
    IDelegateOption[]
  >([
    ...employeesDelegatedToMe,
    { employeeName: 'Facility List', hcaid: facilityId },
    { employeeName: 'My List', hcaid: userId }
  ]);

  // Dropdown states
  const [isDelegateOpen, setIsDelegateOpen] = useState(false);
  const [isDeptOpen, setIsDeptOpen] = useState(false);
  const [isFacilityOpen, setIsFacilityOpen] = useState(false);
  const [isUnitOpen, setIsUnitOpen] = useState(false);
  const [activeSearch, setActiveSearch] = useState<string>('');
  const [query, setQuery] = useState('');
  const [selectedDepts, setSelectedDepts] = useState<string[]>(depts);

  const debouncedQuery = useDebounceValue(query, 250);

  const { pathname } = useLocation();
  const page = pathname.split('/')[1];
  const pageTitle = page.toUpperCase();

  const filteredFavUnits = favUnits.filter(
    unit => unit.facilityId === facilityId
  );

  const alphabetizedDelegateListOptions = useMemo(
    () =>
      delegateListOptions.sort((a: IDelegateOption, b: IDelegateOption) => {
        return a.employeeName > b.employeeName ? 1 : -1;
      }),
    [delegateListOptions]
  );

  const searchResults = useMemo(() => {
    switch (activeSearch) {
      case 'dept':
        return depts.filter((dept: string) =>
          dept.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
        );
      default:
        return undefined;
    }
  }, [activeSearch, depts, debouncedQuery]);

  const handleTypeAheadChange = useCallback(
    (e: any) => setQuery(e.target.value),
    []
  );

  useEffect(() => {
    if (pathname.includes('issues')) {
      dispatch(setCurrentIssuePage(1));
    } else if (pathname.includes('feedback')) {
      dispatch(setCurrentFeedbackPage(1));
    }
  }, [selectedDelegatedEmployee]);

  useEffect(() => {
    if (
      userSection === 'employee' &&
      pathname.includes('list') &&
      selectedDelegate.employeeName === 'Facility List'
    ) {
      dispatch(clearEmployeesToRound());
      setSelectedDelegate({ employeeName: 'My List', hcaid: userId });
    }
    setDelegateListOptions(
      pathname.includes('issues') || pathname.includes('feedback')
        ? [
            ...employeesDelegatedToMe,
            { employeeName: 'Facility List', hcaid: facilityId },
            { employeeName: 'My List', hcaid: userId }
          ]
        : [
            ...employeesDelegatedToMe,
            { employeeName: 'My List', hcaid: userId }
          ]
    );
  }, [employeesDelegatedToMe, pathname]);

  useEffect(() => {
    if (pathname.includes('employees')) {
      if (
        !pathname.includes('stoplight') &&
        selectedDelegate.employeeName !== 'Facility List'
      ) {
        dispatch(
          getEmployeesToRound({
            hcaid: selectedDelegate.hcaid
          })
        );
      } else {
        dispatch(setDelegatedEmployee(selectedDelegate));
      }
    }
  }, [pathname, selectedDelegate]);

  const setOption = useCallback(
    (value: string) => {
      if (value === 'all') {
        setIsFavsChecked(false);
        setIsAllChecked(!isAllChecked);
        setUnitOptions(
          unitOptions.map(option => ({
            ...option,
            checked: !isAllChecked
          }))
        );
      } else if (value === 'favs') {
        setIsAllChecked(false);
        setIsFavsChecked(!isFavsChecked);
        setUnitOptions(
          unitOptions.map(option => {
            return isFavUnit(filteredFavUnits, option.id)
              ? {
                  ...option,
                  checked: !isFavsChecked
                }
              : {
                  ...option,
                  checked: false
                };
          })
        );
      } else {
        const copyUnitOptions = [...unitOptions];
        const optionToUpdate = copyUnitOptions.find(
          (unit: IUnitOption) => unit.id === value
        );
        if (optionToUpdate) optionToUpdate.checked = !optionToUpdate.checked;
        setUnitOptions(copyUnitOptions);
      }
    },
    [unitOptions]
  );

  const placeholder = useMemo(() => {
    if (isAllChecked) return 'All Units';
    if (isFavsChecked && selectedUnits.length === 1)
      return selectedUnits[0].unit;
    if (isFavsChecked) return 'Favorite Units';
    if (!unitOptions.length) return '';
    if (selectedUnits.length === 0) return `No units selected`;
    if (selectedUnits.length === 1) return selectedUnits[0].unit;
    return `${selectedUnits.length} units selected`;
  }, [isAllChecked, isFavsChecked, selectedUnits]);

  const facilityHandler = (e: React.BaseSyntheticEvent<MouseEvent>) => {
    const { value } = e.target;
    dispatch(setAdminFacility(value));
    if (value && value !== facilityId) {
      setSelectedFacility(value);
      dispatch(getAllUnits({ facilityId: value }));
    }
  };

  const handleClickDelegates = (employee: IDelegateOption) => {
    setIsDelegateOpen(false);
    setSelectedDelegate(employee);
  };

  useEffect(() => {
    if (userId) {
      dispatch(getFacilityUnits({ facilityId, isUnfiltered: false }));
    }
    setSelectedFacility(facilityId);
    if (facilityId) {
      dispatch(getAllUnits({ facilityId }));
    }
    dispatch(setAdminFacility(facilityId));
  }, [facilityId, userId]);

  useEffect(() => {
    if (unitOptions.length > 0) {
      const payload = unitOptions
        .filter((option: IUnitOption) => option.checked)
        .map(option => ({ id: option.id, unit: option.value }));
      dispatch(setSelectedUnits(payload));
      dispatch(setFavsPrevChecked(isFavsChecked));
      dispatch(setAllPrevChecked(isAllChecked));
    }
  }, [unitOptions]);

  useEffect(() => {
    setSelectedDepts(
      selectedDeptsFromStore.length > 0
        ? depts.filter(d => selectedDeptsFromStore.includes(d))
        : depts
    );
  }, [depts]);

  useEffect(() => {
    dispatch(setSelectedDeptsInStore(selectedDepts));
  }, [selectedDepts]);

  useEffect(() => {
    if (units.length > 0) {
      if (selectedUnits.length > 0) {
        if (!isAllPrevChecked && !isFavsPrevChecked)
          return setUnitOptions(
            units.map((unit: Unit) => ({
              id: unit.unitId,
              value: unit.unit,
              checked: selectedUnits.some(
                (u: { id: string; unit: string }) => u.id === unit.unitId
              )
            }))
          );
        if (isFavsPrevChecked) {
          setUnitOptions(
            units.map((unit: Unit) => {
              return isFavUnit(filteredFavUnits, unit.unitId)
                ? {
                    id: unit.unitId,
                    value: unit.unit,
                    checked: true
                  }
                : {
                    id: unit.unitId,
                    value: unit.unit,
                    checked: false
                  };
            })
          );
          return setIsFavsChecked(true);
        }
        setUnitOptions(
          units.map((unit: Unit) => ({
            id: unit.unitId,
            value: unit.unit,
            checked: true
          }))
        );
        return setIsAllChecked(true);
      }
      if (visited) {
        return setUnitOptions(
          units.map((unit: Unit) => ({
            id: unit.unitId,
            value: unit.unit,
            checked: false
          }))
        );
      }
      if (filteredFavUnits.length) {
        setUnitOptions(
          units.map((unit: Unit) => {
            return isFavUnit(filteredFavUnits, unit.unitId)
              ? {
                  id: unit.unitId,
                  value: unit.unit,
                  checked: true
                }
              : {
                  id: unit.unitId,
                  value: unit.unit,
                  checked: false
                };
          })
        );
        return setIsFavsChecked(true);
      }
      setUnitOptions(
        units.map((unit: Unit) => ({
          id: unit.unitId,
          value: unit.unit,
          checked: true
        }))
      );
      return setIsAllChecked(true);
    }
    return undefined;
  }, [units, favUnits]);

  return (
    <SubToolbar id="SubNav">
      <TitleContainer>
        <Title id={pageTitle === 'SUPPORT' ? view : pageTitle}>
          {pageTitle === 'SUPPORT'
            ? view?.includes('csc-list')
              ? 'CSC LIST'
              : 'CNEd LIST'
            : pageTitle}
        </Title>
        <SubTitle>
          {divisionTitle} | {facilityTitle}
        </SubTitle>
      </TitleContainer>
      {pageTitle === 'SUPPORT' &&
      (view?.includes('csc-list') || view?.includes('cned-list')) &&
      depts?.length > 0 ? (
        <div
          className="d-flex"
          style={{ flexBasis: '25%', marginLeft: '58px' }}
        >
          <DeptMultiSelectDropdown
            id={`${view}-dept-dropdown`}
            closeOnClick={false}
            focused={activeSearch === 'dept'}
            name="dept"
            open={isDeptOpen}
            options={depts}
            placeholder={
              selectedDeptsFromStore.length === selectedDepts.length
                ? depts.length > 0 &&
                  selectedDeptsFromStore.length === depts.length
                  ? 'All Departments'
                  : depts.length > 0 && selectedDeptsFromStore.length > 1
                  ? `${selectedDeptsFromStore.length} Departments`
                  : depts.length > 0 &&
                    selectedDeptsFromStore.length === 1 &&
                    selectedDeptsFromStore[0]
                  ? selectedDeptsFromStore[0]
                  : 'Select a department'
                : ''
            }
            query={query}
            searchResults={searchResults}
            selectedDepts={selectedDeptsFromStore}
            typeAhead
            setActiveSearch={setActiveSearch}
            setOpen={setIsDeptOpen}
            setQuery={setQuery}
            setSelectedDepts={setSelectedDepts}
            setTypeAhead={setActiveSearch}
            setTypeAheadChange={handleTypeAheadChange}
          />
        </div>
      ) : page === 'admin' && (view === 'areas' || view === 'discharges') ? (
        <div
          className="d-flex"
          style={{ flexBasis: '25%', marginLeft: '58px' }}
        >
          <Dropdown
            id="Facility-Selector"
            open={isFacilityOpen}
            placeholder={
              facilities.find((f: IFacility) => f.id === selectedFacility)
                ?.value || ''
            }
            width="92%"
            setOpen={setIsFacilityOpen}
          >
            {facilities &&
              facilities.length > 0 &&
              facilities.map((item: IFacility) => {
                return (
                  <NeuOption
                    id={`Facility-Option-${item.id}`}
                    className={`dropdown-hover-item${
                      selectedFacility === item.id ? ' selected' : ''
                    }`}
                    key={item.id}
                    value={item.id}
                    onClick={facilityHandler}
                  >
                    {toTitleCaseSpecial(item.value)}
                  </NeuOption>
                );
              })}
          </Dropdown>
        </div>
      ) : userSection === 'patient' ||
        (userSection === 'reports' && !view?.includes('employee')) ? (
        <div
          className="d-flex"
          style={{ flexBasis: '25%', marginLeft: '58px' }}
        >
          <Dropdown
            closeOnClick={false}
            open={isUnitOpen}
            placeholder={placeholder}
            width="92%"
            setOpen={setIsUnitOpen}
          >
            <StyledOptionDiv key="Favs">
              <StyledCheckbox
                id="Fav-Unit-Checkbox"
                name="option-favs"
                checked={isFavsChecked}
                type="checkbox"
                readOnly
              />
              <StyledCheckmark onClick={() => setOption('favs')} />
              <StyledCheckboxLabel
                content="Select My Favorite Units"
                for="option-favs"
              />
            </StyledOptionDiv>
            <StyledOptionDiv key="All">
              <StyledCheckbox
                id="All-Option-Checkbox"
                name="option-all"
                checked={isAllChecked}
                type="checkbox"
                readOnly
              />
              <StyledCheckmark onClick={() => setOption('all')} />
              <StyledCheckboxLabel
                content="Select All Units"
                for="option-all"
              />
            </StyledOptionDiv>
            <NeuDivider />
            {[...unitOptions]
              .sort((a: IUnitOption, b: IUnitOption) =>
                a.value > b.value ? 1 : -1
              )
              .map((option: IUnitOption) => (
                <StyledOptionDiv key={option.id}>
                  <StyledCheckbox
                    id={`Unit-Options-${option.id}`}
                    name={option.value}
                    checked={option.checked}
                    type="checkbox"
                    disabled={isAllChecked || isFavsChecked}
                    readOnly
                  />
                  <StyledCheckmark
                    disabled={isAllChecked || isFavsChecked}
                    onClick={
                      isAllChecked || isFavsChecked
                        ? () => {}
                        : () => setOption(option.id)
                    }
                  />
                  <StyledCheckboxLabel
                    content={option.value}
                    for={option.value}
                  />
                </StyledOptionDiv>
              ))}
          </Dropdown>
        </div>
      ) : (
        userSection === 'employee' &&
        view !== 'stoplight' && (
          <div className="d-flex" style={{ width: '25%', marginLeft: '58px' }}>
            <Dropdown
              open={isDelegateOpen}
              placeholder={selectedDelegate.employeeName}
              width="92%"
              setOpen={setIsDelegateOpen}
            >
              {alphabetizedDelegateListOptions.map(
                (employee: IDelegateOption) => (
                  <NeuOption
                    id={`Delegate-List-Item-${employee.hcaid}`}
                    className={`dropdown-hover-item${
                      selectedDelegate.employeeName === employee.employeeName
                        ? ' selected'
                        : ''
                    }`}
                    onClick={() => handleClickDelegates(employee)}
                    key={employee.hcaid}
                  >
                    {employee.employeeName}
                  </NeuOption>
                )
              )}
            </Dropdown>
          </div>
        )
      )}
    </SubToolbar>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  depts: state.EmployeeReducer.depts,
  employeesDelegatedToMe: state.EmployeeReducer.employeesDelegatedToMe,
  employeesToRound: state.EmployeeReducer.employeesToRound,
  facilities: state.UserReducer.facilities,
  facilityId: state.AuthorizedUser.authorizedUser.facilityId,
  favUnits: state.AuthorizedUser.authorizedUser.primaryUnit,
  isAllPrevChecked: state.ConfigReducer.isAllPrevChecked,
  isFavsPrevChecked: state.ConfigReducer.isFavsPrevChecked,
  selectedDelegatedEmployee: state.EmployeeReducer.selectedDelegatedEmployee,
  selectedDeptsFromStore: state.EmployeeReducer.selectedDepts,
  selectedUnits: state.ConfigReducer.selectedUnits,
  units: state.ConfigReducer.units,
  userId: state.AuthorizedUser.authorizedUser.hcaid,
  userSection: state.UserReducer.userSection,
  visited: state.ConfigReducer.unitsSelected
});

export default connect(mapReduxStateToProps)(SubNav);
